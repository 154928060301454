import React from 'react';

export default ({ className, ...other }) => (
  <svg
    {...other}
    className={`icon w-logo-full ${className || ''}`}
    viewBox="0 0 145.16 145.527"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      xmlns="http://www.w3.org/2000/svg"
      id="Group_57"
      data-name="Group 57"
      transform="translate(-7.597 -547.137)"
    >
      <g
        id="Group_58"
        data-name="Group 58"
        transform="translate(7.597 547.137)"
      >
        <g id="Group_57-2" data-name="Group 57">
          <path
            id="Path_435"
            data-name="Path 435"
            d="M80.177,550.765a69.147,69.147,0,0,1,68.44,59.328,9.831,9.831,0,0,0,0,19.615,69.14,69.14,0,0,1-136.88,0,9.831,9.831,0,0,0,0-19.615,69.147,69.147,0,0,1,68.44-59.328m0-3.628A72.775,72.775,0,0,0,8.145,609.583L7.6,613.439l3.886.273a6.2,6.2,0,0,1,0,12.377l-3.886.273.548,3.857a72.768,72.768,0,0,0,144.064,0l.548-3.857-3.886-.273a6.2,6.2,0,0,1,0-12.377l3.886-.273-.548-3.857a72.774,72.774,0,0,0-72.032-62.446Z"
            transform="translate(-7.597 -547.137)"
            fill="#22ddcb"
          />
        </g>
      </g>
      <g
        id="Group_70"
        data-name="Group 70"
        transform="translate(24.726 559.619)"
      >
        <g id="Group_59" data-name="Group 59" transform="translate(0 33.079)">
          <path
            id="Path_436"
            data-name="Path 436"
            d="M70.649,706.007a3.079,3.079,0,0,1-2.565-.048q-.292-.119-.942-.411t-.953-.442a3.076,3.076,0,0,1-1.715-1.893,3.882,3.882,0,0,1,.321-2.718,4.863,4.863,0,0,1,1.073-1.559,3.005,3.005,0,0,1,1.429-.784,2.59,2.59,0,0,1,1.59.165.228.228,0,0,1,.128.141.257.257,0,0,1-.01.194l-.681,1.512a.368.368,0,0,1-.157.192.536.536,0,0,1-.273.024,1.388,1.388,0,0,0-.933.124,1.535,1.535,0,0,0-.57.71q-.542,1.2.781,1.85.282.14.879.408t.909.4a1.283,1.283,0,0,0,1.9-.642,1.538,1.538,0,0,0,.149-.9,1.333,1.333,0,0,0-.52-.779.44.44,0,0,1-.166-.216.391.391,0,0,1,.043-.249l.681-1.512a.256.256,0,0,1,.138-.136.228.228,0,0,1,.191,0,2.588,2.588,0,0,1,1.178,1.081,3,3,0,0,1,.36,1.59,4.858,4.858,0,0,1-.456,1.837A3.932,3.932,0,0,1,70.649,706.007Z"
            transform="translate(-64.351 -698.094)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_60"
          data-name="Group 60"
          transform="translate(6.934 21.581)"
        >
          <path
            id="Path_437"
            data-name="Path 437"
            d="M92.659,668.327a3.084,3.084,0,0,1-2.493-.658q-.265-.192-.8-.609t-.794-.634a3.089,3.089,0,0,1-1.243-2.268,4.479,4.479,0,0,1,3.205-4.1,3.09,3.09,0,0,1,2.5.657q.548.4.815.607t.777.636a3.1,3.1,0,0,1,1.247,2.255,4.638,4.638,0,0,1-3.216,4.118Zm1.171-3.634a1.624,1.624,0,0,0-.651-1q-.519-.435-.749-.615t-.76-.563a1.626,1.626,0,0,0-1.125-.39,1.3,1.3,0,0,0-.886.538,1.312,1.312,0,0,0-.314,1,1.624,1.624,0,0,0,.651,1q.25.21.738.591t.771.587a1.623,1.623,0,0,0,1.125.39,1.314,1.314,0,0,0,.893-.547A1.3,1.3,0,0,0,93.83,664.694Z"
            transform="translate(-87.327 -659.998)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_61"
          data-name="Group 61"
          transform="translate(15.655 10.962)"
        >
          <path
            id="Path_438"
            data-name="Path 438"
            d="M121.173,635.221a.281.281,0,0,1-.2-.107l-4.689-5.953a.3.3,0,0,1-.069-.22.274.274,0,0,1,.111-.2l1.036-.816a.428.428,0,0,1,.449-.072l4.86,1.716-2.645-3.357a.306.306,0,0,1-.069-.22.274.274,0,0,1,.111-.2l1.165-.918a.276.276,0,0,1,.219-.061.3.3,0,0,1,.2.119l4.682,5.944a.3.3,0,0,1,.069.22.275.275,0,0,1-.111.2l-1.036.816a.426.426,0,0,1-.449.071l-4.742-1.586,2.543,3.228a.274.274,0,0,1,.061.22.3.3,0,0,1-.119.2l-1.165.918A.279.279,0,0,1,121.173,635.221Z"
            transform="translate(-116.219 -624.813)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_62"
          data-name="Group 62"
          transform="translate(27.641 4.083)"
        >
          <path
            id="Path_439"
            data-name="Path 439"
            d="M159.25,612.077a.277.277,0,0,1-.164-.15l-3.124-6.9a.307.307,0,0,1-.015-.23.277.277,0,0,1,.155-.167l1.2-.544a.428.428,0,0,1,.453.038l4.305,2.833-1.761-3.894a.3.3,0,0,1-.015-.231.275.275,0,0,1,.155-.166l1.351-.611a.275.275,0,0,1,.227-.007.305.305,0,0,1,.164.163L165.3,609.1a.3.3,0,0,1,.014.23.273.273,0,0,1-.155.166l-1.2.544a.427.427,0,0,1-.453-.038l-4.223-2.678,1.694,3.745a.275.275,0,0,1,.007.228.306.306,0,0,1-.163.164l-1.351.611A.28.28,0,0,1,159.25,612.077Z"
            transform="translate(-155.932 -602.022)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_63"
          data-name="Group 63"
          transform="translate(41.211 0.606)"
        >
          <path
            id="Path_440"
            data-name="Path 440"
            d="M202.451,599.526a.282.282,0,0,1-.125-.184L200.9,591.9a.305.305,0,0,1,.04-.228.275.275,0,0,1,.19-.126l5.413-1.037a.293.293,0,0,1,.228.045.3.3,0,0,1,.127.2l.218,1.135a.276.276,0,0,1-.046.223.306.306,0,0,1-.2.121l-3.624.694.279,1.456,3.372-.646a.3.3,0,0,1,.355.241l.2,1.044a.3.3,0,0,1-.241.355l-3.372.646.286,1.491,3.715-.712a.3.3,0,0,1,.355.241l.218,1.135a.276.276,0,0,1-.046.223.3.3,0,0,1-.2.121l-5.5,1.055A.281.281,0,0,1,202.451,599.526Z"
            transform="translate(-200.894 -590.503)"
            fill="#edeceb"
          />
        </g>
        <g id="Group_64" data-name="Group 64" transform="translate(54.107 0)">
          <path
            id="Path_441"
            data-name="Path 441"
            d="M244.537,595.99a3.078,3.078,0,0,1-.916-2.4q0-.315.03-1.027t.053-1.049a3.075,3.075,0,0,1,1.114-2.3,3.886,3.886,0,0,1,2.64-.719,4.853,4.853,0,0,1,1.847.413,3.006,3.006,0,0,1,1.262,1.032,2.589,2.589,0,0,1,.441,1.537.228.228,0,0,1-.082.172.258.258,0,0,1-.184.063l-1.657-.066a.364.364,0,0,1-.237-.074.531.531,0,0,1-.124-.245,1.39,1.39,0,0,0-.464-.819,1.533,1.533,0,0,0-.872-.262q-1.318-.052-1.423,1.416-.024.314-.05.968t-.027.992q-.012,1.472,1.306,1.524a1.537,1.537,0,0,0,.89-.2,1.334,1.334,0,0,0,.527-.773.444.444,0,0,1,.138-.234.4.4,0,0,1,.247-.055l1.657.066a.261.261,0,0,1,.178.077.229.229,0,0,1,.069.178,2.587,2.587,0,0,1-.562,1.5,3.008,3.008,0,0,1-1.34.928,4.862,4.862,0,0,1-1.875.264A3.931,3.931,0,0,1,244.537,595.99Z"
            transform="translate(-243.621 -588.494)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_65"
          data-name="Group 65"
          transform="translate(67.824 1.406)"
        >
          <path
            id="Path_442"
            data-name="Path 442"
            d="M289.7,601.536a.273.273,0,0,1-.028-.226l1.6-5.76L289.3,595a.306.306,0,0,1-.186-.137.273.273,0,0,1-.028-.226l.35-1.26a.3.3,0,0,1,.374-.211l6.108,1.7a.3.3,0,0,1,.211.374l-.35,1.26a.277.277,0,0,1-.141.179.306.306,0,0,1-.23.021l-1.98-.551-1.6,5.76a.273.273,0,0,1-.141.179.3.3,0,0,1-.23.021l-1.564-.435A.3.3,0,0,1,289.7,601.536Z"
            transform="translate(-289.071 -593.153)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_66"
          data-name="Group 66"
          transform="translate(77.758 5.98)"
        >
          <path
            id="Path_443"
            data-name="Path 443"
            d="M322,615.484a.278.278,0,0,1,.013-.222l3.391-6.79a.276.276,0,0,1,.172-.149.291.291,0,0,1,.22.018l1.493.746a.279.279,0,0,1,.131.391l-3.391,6.79a.291.291,0,0,1-.164.146.275.275,0,0,1-.227-.016l-1.494-.746A.28.28,0,0,1,322,615.484Z"
            transform="translate(-321.983 -608.309)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_67"
          data-name="Group 67"
          transform="translate(85.731 11.74)"
        >
          <path
            id="Path_444"
            data-name="Path 444"
            d="M348.458,632.726a3.083,3.083,0,0,1,.657-2.494q.191-.265.608-.8t.634-.794a3.089,3.089,0,0,1,2.267-1.245,4.476,4.476,0,0,1,4.107,3.2,3.089,3.089,0,0,1-.656,2.5q-.4.548-.606.815t-.636.778a3.093,3.093,0,0,1-2.254,1.248,4.639,4.639,0,0,1-4.12-3.214Zm3.634,1.169a1.624,1.624,0,0,0,1-.652q.435-.52.615-.75t.563-.76a1.628,1.628,0,0,0,.389-1.125,1.477,1.477,0,0,0-1.538-1.2,1.624,1.624,0,0,0-1,.652q-.21.25-.591.739t-.587.771a1.625,1.625,0,0,0-.389,1.126,1.312,1.312,0,0,0,.547.893A1.3,1.3,0,0,0,352.093,633.895Z"
            transform="translate(-348.401 -627.391)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_68"
          data-name="Group 68"
          transform="translate(94.333 20.451)"
        >
          <path
            id="Path_445"
            data-name="Path 445"
            d="M376.9,661.214a.282.282,0,0,1,.106-.2l5.95-4.694a.305.305,0,0,1,.22-.07.275.275,0,0,1,.2.111l.817,1.036a.429.429,0,0,1,.072.449l-1.713,4.861,3.355-2.647a.3.3,0,0,1,.22-.07.275.275,0,0,1,.2.111l.919,1.164a.272.272,0,0,1,.061.219.3.3,0,0,1-.119.2l-5.94,4.687a.3.3,0,0,1-.221.069.275.275,0,0,1-.2-.111l-.817-1.036a.427.427,0,0,1-.072-.449l1.582-4.744L378.3,662.65a.275.275,0,0,1-.219.062.3.3,0,0,1-.2-.119l-.919-1.164A.281.281,0,0,1,376.9,661.214Z"
            transform="translate(-376.9 -656.254)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_69"
          data-name="Group 69"
          transform="translate(102.124 32.86)"
        >
          <path
            id="Path_446"
            data-name="Path 446"
            d="M402.716,701.212a2.81,2.81,0,0,1,.282-1.454,2.012,2.012,0,0,1,.88-.88.228.228,0,0,1,.191,0,.26.26,0,0,1,.139.135l.65,1.411a.491.491,0,0,1,.053.226.762.762,0,0,1-.069.244,1.176,1.176,0,0,0-.221.622,1.748,1.748,0,0,0,.195.855,1.984,1.984,0,0,0,.58.8.59.59,0,0,0,.624.086.519.519,0,0,0,.292-.328,1.554,1.554,0,0,0,.02-.626q-.05-.4-.215-1.122a4.825,4.825,0,0,1-.109-2.276,1.988,1.988,0,0,1,1.192-1.32,2.171,2.171,0,0,1,1.34-.173,2.619,2.619,0,0,1,1.28.671,4.372,4.372,0,0,1,1.007,1.433,4.13,4.13,0,0,1,.421,1.766,3.046,3.046,0,0,1-.322,1.421,1.878,1.878,0,0,1-.771.824.256.256,0,0,1-.193.012.227.227,0,0,1-.142-.127l-.684-1.485a.449.449,0,0,1,.025-.448.72.72,0,0,0,.225-.5,1.429,1.429,0,0,0-.149-.728,1.391,1.391,0,0,0-.477-.616.574.574,0,0,0-.579-.029.589.589,0,0,0-.336.541,5.293,5.293,0,0,0,.192,1.351,7.475,7.475,0,0,1,.279,1.793,2.2,2.2,0,0,1-.3,1.192,2.381,2.381,0,0,1-2.4.944,2.849,2.849,0,0,1-1.339-.772,5.13,5.13,0,0,1-1.053-1.546A5.064,5.064,0,0,1,402.716,701.212Z"
            transform="translate(-402.712 -697.366)"
            fill="#edeceb"
          />
        </g>
      </g>
      <g
        id="Group_81"
        data-name="Group 81"
        transform="translate(29.661 646.661)"
      >
        <g id="Group_71" data-name="Group 71" transform="translate(0 1.012)">
          <path
            id="Path_447"
            data-name="Path 447"
            d="M80.716,884.164a3.081,3.081,0,0,1,1.37-2.17q.253-.189.84-.593t.872-.586a3.079,3.079,0,0,1,2.508-.487,3.885,3.885,0,0,1,2.157,1.682,4.864,4.864,0,0,1,.778,1.726,3.01,3.01,0,0,1-.069,1.629,2.59,2.59,0,0,1-.965,1.274.231.231,0,0,1-.187.038.258.258,0,0,1-.161-.11l-.941-1.365a.368.368,0,0,1-.083-.234.531.531,0,0,1,.121-.246,1.391,1.391,0,0,0,.378-.863,1.539,1.539,0,0,0-.313-.855,1.281,1.281,0,0,0-1.987-.291q-.267.168-.805.54c-.359.247-.629.438-.81.573a1.282,1.282,0,0,0-.437,1.96,1.54,1.54,0,0,0,.692.593,1.335,1.335,0,0,0,.936-.042.441.441,0,0,1,.27-.03.394.394,0,0,1,.192.165l.94,1.366a.258.258,0,0,1,.044.188.228.228,0,0,1-.1.162,2.594,2.594,0,0,1-1.535.448,3.014,3.014,0,0,1-1.547-.516,4.869,4.869,0,0,1-1.335-1.342A3.935,3.935,0,0,1,80.716,884.164Z"
            transform="translate(-80.702 -880.235)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_72"
          data-name="Group 72"
          transform="translate(8.693 10.757)"
        >
          <path
            id="Path_448"
            data-name="Path 448"
            d="M109.529,917.548a3.082,3.082,0,0,1,.833-2.44q.21-.251.663-.754t.689-.747a3.089,3.089,0,0,1,2.35-1.081,4.479,4.479,0,0,1,3.868,3.487,3.089,3.089,0,0,1-.832,2.449q-.436.52-.662.77c-.157.174-.387.417-.691.73a3.092,3.092,0,0,1-2.337,1.084,4.639,4.639,0,0,1-3.881-3.5Zm3.542,1.425a1.627,1.627,0,0,0,1.04-.579q.471-.487.666-.7t.616-.718a1.623,1.623,0,0,0,.468-1.095,1.3,1.3,0,0,0-.473-.922,1.314,1.314,0,0,0-.975-.384,1.624,1.624,0,0,0-1.041.579q-.229.234-.642.694t-.64.728a1.623,1.623,0,0,0-.468,1.1,1.313,1.313,0,0,0,.483.93A1.3,1.3,0,0,0,113.071,918.973Z"
            transform="translate(-109.504 -912.525)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_73"
          data-name="Group 73"
          transform="translate(18.868 17.511)"
        >
          <path
            id="Path_449"
            data-name="Path 449"
            d="M143.227,941.9a.286.286,0,0,1,.023-.221l3.7-6.629a.271.271,0,0,1,.179-.141.291.291,0,0,1,.218.028l1.428.8a.28.28,0,0,1,.113.4l-2.945,5.283,3.06,1.706a.3.3,0,0,1,.117.412l-.6,1.081a.274.274,0,0,1-.179.141.3.3,0,0,1-.228-.034l-4.742-2.644A.281.281,0,0,1,143.227,941.9Z"
            transform="translate(-143.215 -934.901)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_74"
          data-name="Group 74"
          transform="translate(30.306 22.308)"
        >
          <path
            id="Path_450"
            data-name="Path 450"
            d="M181.15,958.482a.281.281,0,0,1-.024-.221L183.349,951a.273.273,0,0,1,.145-.175.292.292,0,0,1,.22-.018l1.563.478a.286.286,0,0,1,.177.14.282.282,0,0,1,.017.225l-1.771,5.783,3.349,1.026a.3.3,0,0,1,.2.38l-.363,1.183a.271.271,0,0,1-.145.175.3.3,0,0,1-.23.015l-5.192-1.59A.282.282,0,0,1,181.15,958.482Z"
            transform="translate(-181.114 -950.797)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_75"
          data-name="Group 75"
          transform="translate(42.634 24.936)"
        >
          <path
            id="Path_451"
            data-name="Path 451"
            d="M222.032,967.544a.276.276,0,0,1-.07-.211l.632-7.552a.306.306,0,0,1,.1-.208.276.276,0,0,1,.217-.07l5.492.461a.3.3,0,0,1,.277.328l-.1,1.153a.271.271,0,0,1-.1.2.3.3,0,0,1-.222.063l-3.677-.308-.124,1.477,3.42.286a.294.294,0,0,1,.208.105.3.3,0,0,1,.07.222l-.09,1.059a.29.29,0,0,1-.1.208.3.3,0,0,1-.223.069l-3.421-.287-.126,1.513,3.77.316a.3.3,0,0,1,.277.328l-.1,1.152a.273.273,0,0,1-.1.2.3.3,0,0,1-.222.064l-5.585-.468A.279.279,0,0,1,222.032,967.544Z"
            transform="translate(-221.96 -959.502)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_76"
          data-name="Group 76"
          transform="translate(54.821 24.624)"
        >
          <path
            id="Path_452"
            data-name="Path 452"
            d="M264,966.429a3.077,3.077,0,0,1-1.337-2.19q-.057-.311-.159-1.015t-.139-1.042a3.075,3.075,0,0,1,.676-2.463,3.881,3.881,0,0,1,2.465-1.188,4.862,4.862,0,0,1,1.891.068,3.019,3.019,0,0,1,1.43.785,2.591,2.591,0,0,1,.714,1.43.23.23,0,0,1-.05.184.264.264,0,0,1-.169.1l-1.642.237a.363.363,0,0,1-.246-.029.531.531,0,0,1-.167-.218,1.385,1.385,0,0,0-.606-.72,1.529,1.529,0,0,0-.9-.1q-1.307.189-1.142,1.651.034.314.128.961t.153.981q.257,1.449,1.563,1.261a1.539,1.539,0,0,0,.839-.357,1.337,1.337,0,0,0,.378-.857.441.441,0,0,1,.093-.255.394.394,0,0,1,.233-.1l1.641-.237a.257.257,0,0,1,.189.043.228.228,0,0,1,.1.163,2.587,2.587,0,0,1-.279,1.574,3,3,0,0,1-1.148,1.157,4.866,4.866,0,0,1-1.794.6A3.936,3.936,0,0,1,264,966.429Z"
            transform="translate(-262.337 -958.47)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_77"
          data-name="Group 77"
          transform="translate(66.169 20.648)"
        >
          <path
            id="Path_453"
            data-name="Path 453"
            d="M305.058,954.379a.277.277,0,0,1-.158-.164l-2.163-5.573-1.916.744a.305.305,0,0,1-.231,0,.275.275,0,0,1-.158-.164L299.959,948a.3.3,0,0,1,.174-.393l5.91-2.294a.3.3,0,0,1,.393.172l.473,1.219a.275.275,0,0,1-.005.228.306.306,0,0,1-.172.154l-1.916.744,2.164,5.573a.278.278,0,0,1-.006.227.306.306,0,0,1-.172.155l-1.513.587A.3.3,0,0,1,305.058,954.379Z"
            transform="translate(-299.937 -945.294)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_78"
          data-name="Group 78"
          transform="translate(77.146 16.998)"
        >
          <path
            id="Path_454"
            data-name="Path 454"
            d="M340.536,941.108a.279.279,0,0,1-.182-.128l-4-6.448a.274.274,0,0,1-.036-.225.288.288,0,0,1,.13-.177l1.418-.881a.287.287,0,0,1,.222-.042.284.284,0,0,1,.18.136l4,6.448a.292.292,0,0,1,.039.216.274.274,0,0,1-.133.185l-1.419.881A.275.275,0,0,1,340.536,941.108Z"
            transform="translate(-336.305 -933.201)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_79"
          data-name="Group 79"
          transform="translate(83.286 8.921)"
        >
          <path
            id="Path_455"
            data-name="Path 455"
            d="M363.9,915.7a.511.511,0,0,1-.3-.069l-6.81-3.877-.088-.061a.235.235,0,0,1-.06-.176.249.249,0,0,1,.081-.179l1.122-1.04a.412.412,0,0,1,.277-.121.476.476,0,0,1,.254.059l4.752,2.791-2.415-4.958a.441.441,0,0,1-.044-.262.432.432,0,0,1,.147-.263l1.122-1.04a.228.228,0,0,1,.18-.063.24.24,0,0,1,.166.077.391.391,0,0,1,.063.085l3.343,7.092a.443.443,0,0,1,.055.292.492.492,0,0,1-.168.29l-1.379,1.279A.493.493,0,0,1,363.9,915.7Z"
            transform="translate(-356.648 -906.441)"
            fill="#edeceb"
          />
        </g>
        <g id="Group_80" data-name="Group 80" transform="translate(92.083)">
          <path
            id="Path_456"
            data-name="Path 456"
            d="M392.424,886.289a.278.278,0,0,1-.218-.043l-6.271-4.254a.307.307,0,0,1-.133-.19.272.272,0,0,1,.046-.223l3.095-4.56a.3.3,0,0,1,.422-.081l.956.649a.271.271,0,0,1,.124.19.3.3,0,0,1-.053.225l-2.072,3.053,1.227.833,1.928-2.841a.3.3,0,0,1,.422-.081l.879.6a.3.3,0,0,1,.081.422l-1.928,2.841,1.256.852,2.124-3.13a.3.3,0,0,1,.422-.081l.957.649a.271.271,0,0,1,.124.19.3.3,0,0,1-.053.225l-3.147,4.637A.283.283,0,0,1,392.424,886.289Z"
            transform="translate(-385.795 -876.884)"
            fill="#edeceb"
          />
        </g>
      </g>
      <g
        id="Group_82"
        data-name="Group 82"
        transform="translate(135.403 619.901)"
      >
        <path
          id="Path_457"
          data-name="Path 457"
          d="M431.049,788.221"
          transform="translate(-431.049 -788.221)"
          fill="#edeceb"
        />
      </g>
      <g
        id="Group_85"
        data-name="Group 85"
        transform="translate(37.003 575.359)"
      >
        <g id="Group_83" data-name="Group 83" transform="translate(0 0)">
          <path
            id="Path_458"
            data-name="Path 458"
            d="M190.6,675.067a.778.778,0,0,1-.752-.585,43,43,0,0,0-83.285,0,.778.778,0,0,1-1.506-.386,44.554,44.554,0,0,1,86.3,0,.777.777,0,0,1-.56.946A.8.8,0,0,1,190.6,675.067Z"
            transform="translate(-105.028 -640.644)"
            fill="#22ddcb"
          />
        </g>
        <g id="Group_84" data-name="Group 84" transform="translate(0 54.66)">
          <path
            id="Path_459"
            data-name="Path 459"
            d="M148.2,856.169a44.507,44.507,0,0,1-43.149-33.452.778.778,0,0,1,1.506-.386,43,43,0,0,0,83.285,0,.777.777,0,0,1,1.506.386A44.506,44.506,0,0,1,148.2,856.169Z"
            transform="translate(-105.028 -821.746)"
            fill="#22ddcb"
          />
        </g>
      </g>
      <g
        id="Group_90"
        data-name="Group 90"
        transform="translate(27.163 615.885)"
      >
        <g id="Group_86" data-name="Group 86" transform="translate(0 0.438)">
          <path
            id="Path_460"
            data-name="Path 460"
            d="M72.48,782.838a.218.218,0,0,1-.056-.154v-6.092a.23.23,0,0,1,.056-.163.193.193,0,0,1,.149-.061h3.834a.209.209,0,0,1,.224.224v.336a.2.2,0,0,1-.06.149.234.234,0,0,1-.164.056H73.282v2.08h2.976a.209.209,0,0,1,.224.224v.318a.209.209,0,0,1-.061.154.22.22,0,0,1-.163.061H73.282v2.155h3.256a.209.209,0,0,1,.224.224v.336a.2.2,0,0,1-.065.154.223.223,0,0,1-.158.061H72.63A.192.192,0,0,1,72.48,782.838Z"
            transform="translate(-72.424 -776.368)"
            fill="#22ddcb"
          />
        </g>
        <g id="Group_87" data-name="Group 87" transform="translate(7.09 0.345)">
          <path
            id="Path_461"
            data-name="Path 461"
            d="M97.087,782.534a2.034,2.034,0,0,1-.858-.639,1.512,1.512,0,0,1-.312-.854.179.179,0,0,1,.056-.131.19.19,0,0,1,.14-.056h.457a.238.238,0,0,1,.233.187,1.119,1.119,0,0,0,.48.676,1.925,1.925,0,0,0,1.133.285,2.044,2.044,0,0,0,1.185-.28.9.9,0,0,0,.392-.774.738.738,0,0,0-.191-.522,1.586,1.586,0,0,0-.569-.359q-.378-.154-1.133-.378a5.617,5.617,0,0,1-1.143-.439,1.7,1.7,0,0,1-.639-.573,1.59,1.59,0,0,1-.215-.863,1.556,1.556,0,0,1,.271-.9,1.808,1.808,0,0,1,.784-.625,2.96,2.96,0,0,1,1.213-.229,2.783,2.783,0,0,1,1.246.256,2.04,2.04,0,0,1,.788.644,1.48,1.48,0,0,1,.289.779.191.191,0,0,1-.2.2h-.467a.234.234,0,0,1-.14-.046.253.253,0,0,1-.094-.14.939.939,0,0,0-.448-.662,1.73,1.73,0,0,0-.98-.261,1.8,1.8,0,0,0-1.007.247.821.821,0,0,0-.374.732.8.8,0,0,0,.173.532,1.447,1.447,0,0,0,.532.364,9.4,9.4,0,0,0,1.049.364,7.488,7.488,0,0,1,1.245.448,1.7,1.7,0,0,1,.676.555,1.494,1.494,0,0,1,.224.854,1.6,1.6,0,0,1-.667,1.367,2.987,2.987,0,0,1-1.805.49A3.346,3.346,0,0,1,97.087,782.534Z"
            transform="translate(-95.916 -776.06)"
            fill="#22ddcb"
          />
        </g>
        <g
          id="Group_88"
          data-name="Group 88"
          transform="translate(14.497 0.438)"
        >
          <path
            id="Path_462"
            data-name="Path 462"
            d="M122.533,782.838a.208.208,0,0,1-.061-.154V777.17h-1.8a.208.208,0,0,1-.215-.215v-.364a.22.22,0,0,1,.061-.163.207.207,0,0,1,.154-.061h4.478a.2.2,0,0,1,.158.061.23.23,0,0,1,.056.163v.364a.208.208,0,0,1-.215.215h-1.791v5.513a.2.2,0,0,1-.065.154.224.224,0,0,1-.158.061h-.448A.207.207,0,0,1,122.533,782.838Z"
            transform="translate(-120.457 -776.368)"
            fill="#22ddcb"
          />
        </g>
        <g id="Group_89" data-name="Group 89" transform="translate(21.784)">
          <path
            id="Path_463"
            data-name="Path 463"
            d="M144.914,778.464a1.588,1.588,0,0,1-.307-.935l-.006-.163.006-.163a1.583,1.583,0,0,1,.31-.933,1.012,1.012,0,0,1,.854-.373,1.055,1.055,0,0,1,.881.4v-1.256a.121.121,0,0,1,.126-.125h.244a.12.12,0,0,1,.125.125v3.612a.12.12,0,0,1-.125.125h-.234a.113.113,0,0,1-.087-.035.127.127,0,0,1-.032-.09v-.234a1.054,1.054,0,0,1-.9.413A1.014,1.014,0,0,1,144.914,778.464Zm1.531-.307a1.056,1.056,0,0,0,.207-.6c0-.039.005-.109.005-.206s0-.172-.005-.212a.928.928,0,0,0-.209-.574.694.694,0,0,0-.568-.242.66.66,0,0,0-.571.245,1.141,1.141,0,0,0-.2.642l-.005.158q0,1.044.772,1.044A.669.669,0,0,0,146.445,778.157Z"
            transform="translate(-144.601 -774.918)"
            fill="#22ddcb"
          />
        </g>
      </g>
      <g
        id="Group_95"
        data-name="Group 95"
        transform="translate(109.291 616.221)"
      >
        <g id="Group_91" data-name="Group 91" transform="translate(0 0)">
          <path
            id="Path_464"
            data-name="Path 464"
            d="M344.6,782.6a.208.208,0,0,1-.061-.154v-.28a.548.548,0,0,1,.233-.41l2.034-2.024a5.8,5.8,0,0,0,.952-.961,1.359,1.359,0,0,0,.261-.793,1.177,1.177,0,0,0-.308-.863,1.4,1.4,0,0,0-1.782.014,1.475,1.475,0,0,0-.4.877.245.245,0,0,1-.093.159.25.25,0,0,1-.149.056h-.448a.189.189,0,0,1-.14-.056.179.179,0,0,1-.056-.131,2.177,2.177,0,0,1,.266-.956,1.96,1.96,0,0,1,.733-.756,2.228,2.228,0,0,1,1.176-.294,2.089,2.089,0,0,1,1.572.546,1.913,1.913,0,0,1,.527,1.385,2.012,2.012,0,0,1-.284,1.059,4.327,4.327,0,0,1-.891.994l-1.838,1.865h2.939a.195.195,0,0,1,.215.215v.355a.208.208,0,0,1-.215.215h-4.086A.208.208,0,0,1,344.6,782.6Z"
            transform="translate(-344.536 -776.029)"
            fill="#22ddcb"
          />
        </g>
        <g
          id="Group_92"
          data-name="Group 92"
          transform="translate(7.445 0.009)"
        >
          <path
            id="Path_465"
            data-name="Path 465"
            d="M369.8,782.072a3.057,3.057,0,0,1-.592-1.842l-.01-.8.01-.83a2.965,2.965,0,0,1,.592-1.833,2.559,2.559,0,0,1,3.526,0,2.963,2.963,0,0,1,.593,1.833q.009.261.009.83,0,.551-.009.8a3.057,3.057,0,0,1-.593,1.842,2.558,2.558,0,0,1-3.526,0Zm2.841-.546a2.252,2.252,0,0,0,.382-1.343q.009-.27.01-.774t-.01-.765a2.234,2.234,0,0,0-.387-1.339,1.443,1.443,0,0,0-2.15,0,2.261,2.261,0,0,0-.383,1.339l-.009.765.009.774a2.254,2.254,0,0,0,.383,1.343,1.457,1.457,0,0,0,2.155,0Z"
            transform="translate(-369.202 -776.06)"
            fill="#22ddcb"
          />
        </g>
        <g
          id="Group_93"
          data-name="Group 93"
          transform="translate(14.861 0.102)"
        >
          <path
            id="Path_466"
            data-name="Path 466"
            d="M395.967,782.838a.22.22,0,0,1-.056-.154v-5.262l-1.568,1.2a.211.211,0,0,1-.139.056.208.208,0,0,1-.159-.093l-.224-.289a.223.223,0,0,1-.047-.131.2.2,0,0,1,.093-.168l2.025-1.567a.442.442,0,0,1,.242-.066h.438a.207.207,0,0,1,.215.215v6.1a.207.207,0,0,1-.215.215h-.457A.193.193,0,0,1,395.967,782.838Z"
            transform="translate(-393.775 -776.368)"
            fill="#22ddcb"
          />
        </g>
        <g
          id="Group_94"
          data-name="Group 94"
          transform="translate(20.822 0.009)"
        >
          <path
            id="Path_467"
            data-name="Path 467"
            d="M414.837,782.623a.222.222,0,0,1-.014-.266l1.436-2a2.175,2.175,0,0,1-.522.056,2.3,2.3,0,0,1-1.175-.312,2.067,2.067,0,0,1-.77-.793,2.168,2.168,0,0,1-.266-1.05,2.248,2.248,0,0,1,.266-1.064,2.093,2.093,0,0,1,.793-.821,2.426,2.426,0,0,1,1.273-.317,2.508,2.508,0,0,1,1.278.308,2.063,2.063,0,0,1,.8.807,2.211,2.211,0,0,1,.271,1.068,2.127,2.127,0,0,1-.182.905,5.18,5.18,0,0,1-.48.812l-1.81,2.537a.9.9,0,0,1-.126.135.258.258,0,0,1-.173.051h-.466A.166.166,0,0,1,414.837,782.623Zm1.758-3.12a1.348,1.348,0,0,0,.532-.5,1.4,1.4,0,0,0,.2-.742,1.44,1.44,0,0,0-.2-.756,1.342,1.342,0,0,0-.532-.5,1.557,1.557,0,0,0-.737-.177,1.51,1.51,0,0,0-.723.177,1.348,1.348,0,0,0-.527.5,1.441,1.441,0,0,0-.2.756,1.4,1.4,0,0,0,.2.742,1.363,1.363,0,0,0,.522.5,1.5,1.5,0,0,0,.728.177A1.554,1.554,0,0,0,416.6,779.5Z"
            transform="translate(-413.526 -776.06)"
            fill="#22ddcb"
          />
        </g>
      </g>
      <g
        id="Group_99"
        data-name="Group 99"
        transform="translate(71.177 580.98)"
      >
        <g id="Group_96" data-name="Group 96" transform="translate(0 0)">
          <path
            id="Path_468"
            data-name="Path 468"
            d="M218.742,663.463a1.894,1.894,0,0,1-.483-1.286q-.007-.175-.006-.57t.006-.583a1.892,1.892,0,0,1,.483-1.286,1.733,1.733,0,0,1,1.312-.47,2.141,2.141,0,0,1,.978.2,1.446,1.446,0,0,1,.829,1.241.117.117,0,0,1-.042.094.151.151,0,0,1-.1.036H221.4a.156.156,0,0,1-.1-.033.2.2,0,0,1-.058-.11,1.1,1.1,0,0,0-.4-.7,1.346,1.346,0,0,0-.78-.2q-1.127,0-1.173,1.257-.007.175-.006.538t.006.551q.045,1.25,1.173,1.25a1.345,1.345,0,0,0,.777-.2,1.088,1.088,0,0,0,.4-.693.2.2,0,0,1,.058-.11.157.157,0,0,1,.1-.032h.324a.151.151,0,0,1,.1.036.118.118,0,0,1,.042.094,1.447,1.447,0,0,1-.829,1.241,2.144,2.144,0,0,1-.978.2A1.734,1.734,0,0,1,218.742,663.463Z"
            transform="translate(-218.253 -659.268)"
            fill="#22ddcb"
          />
        </g>
        <g
          id="Group_97"
          data-name="Group 97"
          transform="translate(6.602 0.065)"
        >
          <path
            id="Path_469"
            data-name="Path 469"
            d="M240.166,663.979a.123.123,0,0,1-.039-.091.149.149,0,0,1,.026-.084l1.484-2.093-1.413-2.015a.149.149,0,0,1-.026-.084.133.133,0,0,1,.129-.129h.37a.213.213,0,0,1,.168.11l1.16,1.639,1.146-1.639a.193.193,0,0,1,.169-.11h.343a.133.133,0,0,1,.129.129.149.149,0,0,1-.026.084l-1.4,2.021,1.483,2.086a.15.15,0,0,1,.026.084.12.12,0,0,1-.042.091.135.135,0,0,1-.094.039h-.369a.2.2,0,0,1-.168-.1L242,662.2l-1.218,1.711a.2.2,0,0,1-.169.1h-.356A.123.123,0,0,1,240.166,663.979Z"
            transform="translate(-240.127 -659.483)"
            fill="#22ddcb"
          />
        </g>
        <g
          id="Group_98"
          data-name="Group 98"
          transform="translate(13.69 0.065)"
        >
          <path
            id="Path_470"
            data-name="Path 470"
            d="M263.649,663.976a.152.152,0,0,1-.039-.107v-4.231a.16.16,0,0,1,.039-.113.134.134,0,0,1,.1-.042h.285a.152.152,0,0,1,.1.026.42.42,0,0,1,.065.065l2.171,3.343v-3.278a.152.152,0,0,1,.042-.113.144.144,0,0,1,.107-.042h.291a.145.145,0,0,1,.155.155v4.225a.154.154,0,0,1-.042.11.139.139,0,0,1-.107.045h-.292a.158.158,0,0,1-.155-.091l-2.171-3.311v3.252a.144.144,0,0,1-.149.149h-.3A.134.134,0,0,1,263.649,663.976Z"
            transform="translate(-263.61 -659.483)"
            fill="#22ddcb"
          />
        </g>
      </g>
      <g
        id="Group_103"
        data-name="Group 103"
        transform="translate(72.243 654.713)"
      >
        <g id="Group_100" data-name="Group 100">
          <path
            id="Path_471"
            data-name="Path 471"
            d="M221.827,908.056a.145.145,0,0,1-.043-.107v-4.231a.162.162,0,0,1,.039-.113.143.143,0,0,1,.11-.042h.3a.164.164,0,0,1,.162.1l1.38,2.65,1.393-2.65a.167.167,0,0,1,.156-.1h.3a.143.143,0,0,1,.11.042.16.16,0,0,1,.039.113v4.231a.144.144,0,0,1-.149.149h-.3a.133.133,0,0,1-.1-.042.151.151,0,0,1-.039-.107v-3.22l-1.108,2.144a.217.217,0,0,1-.208.136h-.194a.223.223,0,0,1-.207-.136l-1.1-2.144v3.22a.144.144,0,0,1-.149.149h-.292A.144.144,0,0,1,221.827,908.056Z"
            transform="translate(-221.784 -903.563)"
            fill="#22ddcb"
          />
        </g>
        <g id="Group_101" data-name="Group 101" transform="translate(7.73)">
          <path
            id="Path_472"
            data-name="Path 472"
            d="M247.433,908.056a.152.152,0,0,1-.039-.107v-4.237a.152.152,0,0,1,.039-.107.134.134,0,0,1,.1-.042h.324a.135.135,0,0,1,.149.149v4.237a.144.144,0,0,1-.149.149h-.324A.134.134,0,0,1,247.433,908.056Z"
            transform="translate(-247.394 -903.563)"
            fill="#22ddcb"
          />
        </g>
        <g id="Group_102" data-name="Group 102" transform="translate(11.637)">
          <path
            id="Path_473"
            data-name="Path 473"
            d="M260.378,908.059a.124.124,0,0,1-.039-.091l.013-.071,1.607-4.192a.2.2,0,0,1,.208-.142h.414a.2.2,0,0,1,.207.142l1.607,4.192.013.071a.121.121,0,0,1-.042.091.137.137,0,0,1-.094.039h-.3a.162.162,0,0,1-.107-.036.173.173,0,0,1-.055-.075l-.363-.933H261.3l-.356.933a.171.171,0,0,1-.162.11h-.311A.124.124,0,0,1,260.378,908.059Zm1.108-1.542h1.775l-.887-2.326Z"
            transform="translate(-260.339 -903.563)"
            fill="#22ddcb"
          />
        </g>
      </g>
      <g
        id="Group_110"
        data-name="Group 110"
        transform="translate(57.542 590.892)"
      >
        <g id="Group_104" data-name="Group 104" transform="translate(8.723)">
          <path
            id="Path_474"
            data-name="Path 474"
            d="M206.646,701.154a4.382,4.382,0,0,1,4.377-4.377h7.2a4.368,4.368,0,0,1,3.686,2.037h5.014a9.033,9.033,0,0,0-8.7-6.706h-7.2a9.056,9.056,0,0,0-9.045,9.045v33.8h4.669Z"
            transform="translate(-201.978 -692.109)"
            fill="#edeceb"
          />
        </g>
        <g id="Group_105" data-name="Group 105" transform="translate(0 8.816)">
          <path
            id="Path_475"
            data-name="Path 475"
            d="M200.8,761.971V757.3H182.121a4.382,4.382,0,0,1-4.377-4.377V730.1a4.363,4.363,0,0,1,2.292-3.824v-4.955a9.045,9.045,0,0,0-6.96,8.779v22.828a9.056,9.056,0,0,0,9.045,9.045Z"
            transform="translate(-173.076 -721.319)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_106"
          data-name="Group 106"
          transform="translate(15.264 8.55)"
        >
          <path
            id="Path_476"
            data-name="Path 476"
            d="M241.7,720.437H223.65v4.668H241.7a4.38,4.38,0,0,1,4.373,4.339V731.4h6.238a1.433,1.433,0,0,0,.568-.849s-2.188-.226-2.188-2.018l-.043-.005A9.026,9.026,0,0,0,241.7,720.437Z"
            transform="translate(-223.65 -720.437)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_107"
          data-name="Group 107"
          transform="translate(27.208 15.009)"
        >
          <path
            id="Path_477"
            data-name="Path 477"
            d="M265.361,742.511v.968c0,1.792-2.138,2.013-2.138,2.013a1.433,1.433,0,0,0,.568.849h6.238v-4.5h-4.669Z"
            transform="translate(-263.223 -741.839)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_108"
          data-name="Group 108"
          transform="translate(9.018 38.576)"
        >
          <path
            id="Path_478"
            data-name="Path 478"
            d="M221.714,819.922a1.433,1.433,0,0,0-.568.849s2.138.221,2.138,2.013v7.533a4.382,4.382,0,0,1-4.377,4.377h-7.2a4.361,4.361,0,0,1-3.774-2.2h-4.977a9.04,9.04,0,0,0,8.75,6.868h7.2a9.055,9.055,0,0,0,9.045-9.045v-10.4h-6.238Z"
            transform="translate(-202.956 -819.922)"
            fill="#edeceb"
          />
        </g>
        <g
          id="Group_109"
          data-name="Group 109"
          transform="translate(35.652 38.576)"
        >
          <path
            id="Path_479"
            data-name="Path 479"
            d="M299.471,819.922h-6.238V821.8a4.367,4.367,0,0,1-2.033,3.657v5.014a9.032,9.032,0,0,0,6.618-7.676l.034,0c0-1.792,2.188-2.018,2.188-2.018A1.433,1.433,0,0,0,299.471,819.922Z"
            transform="translate(-291.2 -819.922)"
            fill="#edeceb"
          />
        </g>
      </g>
    </g>
  </svg>
);
