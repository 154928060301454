import React, { useRef, useEffect } from 'react';
import Icon from 'icon';
import Button from 'button';
import { Link } from 'gatsby';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import data from './data';
import s from './nav.module.scss';

export default function Nav({ active, handleNav, inView }) {
  const nav = useRef({});
  useEffect(() => {
    try {
      if (active && window.innerWidth < 769) {
        disableBodyScroll(nav.current);
      } else {
        clearAllBodyScrollLocks();
      }
    } catch (error) {}
  }, [active]);

  return (
    <div
      ref={nav}
      className={`${s.nav} ${active ? s.active : ''} ${inView ? s.inView : ''}`}
    >
      <div className="container">
        <div className={s.navListWrapper}>
          <Link to="/" className={s.menuLogo}>
            <img src="/svg/logo-color.svg" alt="logo" />
          </Link>
          <button type="button" className={s.close} onClick={handleNav}>
            <span className={s.closeText}>close</span> <Icon icon="times" />
          </button>
          <div className={s.navLists}>
            <ul className={s.navList}>
              {data.pages.map(({ text, href }) => (
                <li className={s.listItem} key={text}>
                  <Link to={href} className={s.pageLink}>
                    {text}
                  </Link>
                </li>
              ))}
            </ul>
            <ul className={s.navList}>
              {data.social.map(({ text, href }) =>
                href ? (
                  <li className={`${s.listItem} ${s.isSocial}`} key={text}>
                    <a
                      href={href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={s.socialLink}
                    >
                      {text}
                    </a>
                  </li>
                ) : (
                  <li
                    className={`${s.listItem} ${s.isSocial} ${s.isTitle}`}
                    key={text}
                  >
                    {text}
                    <span className={s.dash}>
                      <Icon icon="dash" />
                    </span>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className={s.buttonWrapper}>
            <Button className={s.button} big href="/contact" reg>
              Let's start your project
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
