export default {
  pages: [
    {
      text: 'about',
      href: '/about/'
    },
    {
      text: 'our work',
      href: '/our-work/'
    },
    {
      text: 'what we do',
      href: '/what-we-do'
    },
    {
      text: 'contact',
      href: '/contact/'
    },
    {
      text: 'careers',
      href: '/coming-soon/'
    }
  ],
  social: [
    {
      text: 'facebook',
      href: 'https://www.facebook.com/cxncollective/'
    },
    {
      text: 'twitter',
      href: 'https://twitter.com/cxncollective/'
    },
    {
      text: 'instagram',
      href: 'https://www.instagram.com/cxn_collective/'
    },
    {
      text: 'linkedin',
      href: 'https://www.linkedin.com/company/cxncollective/'
    },
    // {
    //   text: 'dribbble',
    //   href: '/'
    // }
  ]
};
