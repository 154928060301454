import React from 'react';

export default ({ className, ...other }) => (
  <svg
    {...other}
    className={`icon w-long-arrow ${className || ''}`}
    viewBox="0 0 11.055 21.966"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      id="Path_93"
      data-name="Path 93"
      d="M1095.505,1331.763a.484.484,0,0,0-.685,0l-4.215,4.216v-19.827a.485.485,0,1,0-.969,0v19.827l-4.216-4.216a.484.484,0,0,0-.685.685l5.042,5.042a.486.486,0,0,0,.686,0l5.042-5.042A.484.484,0,0,0,1095.505,1331.763Z"
      transform="translate(-1084.593 -1315.667)"
      fill="currentColor"
    />
  </svg>
);
