import React from 'react';

export default () => (
    <svg  viewBox="0 0 79.536 79.536" xmlns="http://www.w3.org/2000/svg">

<g>
	<path fill="currentColor"  d="M48.074,0H9.134v79.536h61.267V23.177L48.074,0z M49.12,10.377l10.512,10.918H49.12V10.377z
		 M15.581,73.095V6.452h28.702v19.677h19.672v46.966H15.581z M57.084,33.019H22.465v-3.234h34.619V33.019z M57.084,46.386H22.465
		v-3.221h34.619V46.386z M57.084,59.766H22.465v-3.221h34.619V59.766z"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
);