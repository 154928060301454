import React from 'react';

export default ({ className, ...other }) => (
  <svg
    {...other}
    className={`icon w-logo ${className || ''}`}
    viewBox="0 0 35.445 46.22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      xmlns="http://www.w3.org/2000/svg"
      id="Group_166"
      data-name="Group 166"
      transform="translate(-455.37 -512.523)"
    >
      <path
        id="Path_525"
        data-name="Path 525"
        d="M475.92,519.729a3.491,3.491,0,0,1,3.487-3.487h5.736a3.48,3.48,0,0,1,2.936,1.623h3.994a7.2,7.2,0,0,0-6.931-5.342h-5.736a7.214,7.214,0,0,0-7.206,7.206v26.927h3.719Z"
        transform="translate(-9.882)"
        fill="currentColor"
      />
      <path
        id="Path_526"
        data-name="Path 526"
        d="M477.46,561.92V558.2H462.576a3.491,3.491,0,0,1-3.487-3.487V536.528a3.476,3.476,0,0,1,1.826-3.046v-3.947a7.205,7.205,0,0,0-5.545,6.994v18.186a7.214,7.214,0,0,0,7.206,7.206Z"
        transform="translate(0 -9.987)"
        fill="currentColor"
      />
      <path
        id="Path_527"
        data-name="Path 527"
        d="M499.2,529.02H484.822v3.719H499.2a3.489,3.489,0,0,1,3.484,3.457v1.557h4.97a1.142,1.142,0,0,0,.453-.676s-1.743-.18-1.743-1.608l-.034,0A7.19,7.19,0,0,0,499.2,529.02Z"
        transform="translate(-17.292 -9.686)"
        fill="currentColor"
      />
      <path
        id="Path_528"
        data-name="Path 528"
        d="M509.57,542.019v.772c0,1.427-1.7,1.6-1.7,1.6a1.142,1.142,0,0,0,.453.676h4.97v-3.587H509.57Z"
        transform="translate(-30.821 -17.003)"
        fill="currentColor"
      />
      <path
        id="Path_529"
        data-name="Path 529"
        d="M487.715,586.954a1.143,1.143,0,0,0-.453.677s1.7.176,1.7,1.6v6a3.491,3.491,0,0,1-3.487,3.487h-5.736a3.475,3.475,0,0,1-3.006-1.752h-3.965a7.2,7.2,0,0,0,6.971,5.471h5.736a7.214,7.214,0,0,0,7.206-7.206v-8.282h-4.97Z"
        transform="translate(-10.216 -43.699)"
        fill="currentColor"
      />
      <path
        id="Path_530"
        data-name="Path 530"
        d="M530.749,586.954h-4.97v1.495a3.481,3.481,0,0,1-1.62,2.913v3.994a7.2,7.2,0,0,0,5.273-6.115l.026,0c0-1.427,1.743-1.607,1.743-1.607A1.143,1.143,0,0,0,530.749,586.954Z"
        transform="translate(-40.387 -43.699)"
        fill="currentColor"
      />
    </g>
  </svg>
);
