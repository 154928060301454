import React from 'react';
// down
export default ({ className, ...other }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28.595 25.229"
    {...other}
    className={`icon w-development ${className || ''}`}
  >
    <g
      xmlns="http://www.w3.org/2000/svg"
      id="Symbol_2_1"
      data-name="Symbol 2 – 1"
      transform="translate(-1200.15 -2062.128)"
    >
      <path
        id="Path_693"
        data-name="Path 693"
        d="M25.4,344.058a.472.472,0,0,0-.666-.053l-3.055,2.6a.472.472,0,0,0,0,.719l3.055,2.6a.472.472,0,1,0,.612-.719l-2.633-2.242,2.633-2.242A.472.472,0,0,0,25.4,344.058Z"
        transform="translate(1186.39 1730.441)"
        fill="currentColor"
      />
      <path
        id="Path_694"
        data-name="Path 694"
        d="M74.683,344a.472.472,0,0,0-.612.719l2.633,2.242-2.633,2.242a.472.472,0,1,0,.612.719l3.055-2.6a.472.472,0,0,0,0-.719Z"
        transform="translate(1143.086 1730.441)"
        fill="currentColor"
      />
      <path
        id="Path_695"
        data-name="Path 695"
        d="M52.75,333.027a.472.472,0,0,0-.559.365l-1.9,9.04a.472.472,0,0,0,.365.559.485.485,0,0,0,.1.01.472.472,0,0,0,.461-.375l1.9-9.04A.472.472,0,0,0,52.75,333.027Z"
        transform="translate(1162.617 1739.431)"
        fill="currentColor"
      />
      <path
        id="Path_696"
        data-name="Path 696"
        d="M2.218,273.505H-20.038a3.173,3.173,0,0,0-3.17,3.17v18.89a3.173,3.173,0,0,0,3.17,3.17H2.218a3.173,3.173,0,0,0,3.17-3.17v-18.89A3.173,3.173,0,0,0,2.218,273.505Zm-22.256.944H2.218a2.228,2.228,0,0,1,2.226,2.226v3.446H-22.264v-3.446A2.228,2.228,0,0,1-20.038,274.449ZM2.218,297.79H-20.038a2.228,2.228,0,0,1-2.226-2.226v-14.5H4.443v14.5A2.228,2.228,0,0,1,2.218,297.79Z"
        transform="translate(1223.358 1788.623)"
        fill="currentColor"
      />
      <path
        id="Path_697"
        data-name="Path 697"
        d="M16.184,289.828a1.494,1.494,0,1,0-1.494-1.494A1.5,1.5,0,0,0,16.184,289.828Zm0-2.044a.55.55,0,1,1-.55.55A.55.55,0,0,1,16.184,287.784Z"
        transform="translate(1192.032 1777.6)"
        fill="currentColor"
      />
      <path
        id="Path_698"
        data-name="Path 698"
        d="M-10.286,289.744a1.5,1.5,0,0,0,1.494-1.494,1.5,1.5,0,0,0-1.494-1.494,1.5,1.5,0,0,0-1.494,1.494A1.5,1.5,0,0,0-10.286,289.744Zm0-2.044a.551.551,0,0,1,.55.55.55.55,0,0,1-.55.55.551.551,0,0,1-.55-.55A.551.551,0,0,1-10.286,287.7Z"
        transform="translate(1213.912 1777.67)"
        fill="currentColor"
      />
      <path
        id="Path_699"
        data-name="Path 699"
        d="M42.656,289.911a1.494,1.494,0,1,0-1.494-1.494A1.5,1.5,0,0,0,42.656,289.911Zm0-2.044a.55.55,0,1,1-.55.55A.55.55,0,0,1,42.656,287.867Z"
        transform="translate(1170.151 1777.531)"
        fill="currentColor"
      />
    </g>
  </svg>
);
