import React from 'react';

export default ({ className, ...other }) => (
  <svg
    {...other}
    className={`icon w16 ${className || ''}`}
    viewBox="0 0 24.292 24.288"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      xmlns="http://www.w3.org/2000/svg"
      id="flaticon1554585991-svg"
      transform="translate(-0.001 -0.5)"
      opacity="0.548"
    >
      <path
        id="Path_582"
        data-name="Path 582"
        d="M15.227.757a5.173,5.173,0,0,0-4.608.61l-.006-.032-3.648.69A6.5,6.5,0,0,0,1.584,7.611a6.249,6.249,0,0,0,.2,2.316l-.622.618C.038,11.672-.285,13.428.258,15.488A12.89,12.89,0,0,0,3.68,21.11,12.888,12.888,0,0,0,9.3,24.533a5.009,5.009,0,0,0,4.94-.9l3.12-3.07v.625a3.547,3.547,0,0,0,4.1,3.557,3.6,3.6,0,0,0,2.829-3.709V11.758a10.694,10.694,0,0,0-3.438-7.578A12.89,12.89,0,0,0,15.227.757ZM3,7.748A5.074,5.074,0,0,1,7.23,3.423L8.6,3.164,3,8.722A4.956,4.956,0,0,1,3,7.748ZM13.243,22.615l0,0C11.6,24.256,7.686,23.1,4.686,20.1a11.456,11.456,0,0,1-3.052-4.978c-.408-1.55-.217-2.82.535-3.572l.222-.22a6.041,6.041,0,0,0,4.855,2.949,2.137,2.137,0,1,0,.008-1.425,4.606,4.606,0,0,1-3.81-2.569l5.679-5.64A6.989,6.989,0,0,0,9.379,6.54,12.916,12.916,0,0,0,12.8,12.088l3.931,3.931a2.156,2.156,0,0,1,.632,1.512v1.031ZM8.758,13.077a.712.712,0,1,1,0,1.006A.711.711,0,0,1,8.758,13.077ZM22.865,21.03a2.211,2.211,0,0,1-1.635,2.3,2.125,2.125,0,0,1-2.444-2.152V17.531a3.589,3.589,0,0,0-1.048-2.518s-4.3-4.309-4.472-4.5h7.648a1.841,1.841,0,0,1,1.331.537,2.232,2.232,0,0,1,.62,1.6V21.03ZM20.915,9.087H12.151c-1.712-2.524-2.14-5.137-.86-6.417,1.636-1.636,5.553-.484,8.553,2.516a10.848,10.848,0,0,1,2.7,4.32A3.328,3.328,0,0,0,20.915,9.087Zm0,0"
        transform="translate(0 0)"
        fill="currentColor"
      />
      <path
        id="Path_583"
        data-name="Path 583"
        d="M424.922,331.449h1.423v4.98h-1.423Zm0,0"
        transform="translate(-404.761 -315.247)"
        fill="currentColor"
      />
    </g>
  </svg>
);
