import React from 'react';

export default ({ className, ...other }) => (
  <svg
    {...other}
    className={`icon w-404 ${className || ''}`}
    viewBox="0 0 327.271 190.27"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      xmlns="http://www.w3.org/2000/svg"
      id="Symbol_3_1"
      data-name="Symbol 3 – 1"
      transform="translate(-828 -203.73)"
    >
      <g id="Group_342" data-name="Group 342" transform="translate(1 17)">
        <g id="Group_341" data-name="Group 341" transform="translate(0 20)">
          <text
            id="_4"
            data-name="4"
            transform="translate(827 315)"
            fill="#f9f9f9"
            font-size="150"
            font-family="Geogrotesque-SemiBold, Geogrotesque"
            font-weight="600"
            letter-spacing="0.15em"
          >
            <tspan x="0" y="0">
              4
            </tspan>
          </text>
          <text
            id="_4-2"
            data-name="4"
            transform="translate(997 315)"
            fill="#f9f9f9"
            font-size="150"
            font-family="Geogrotesque-SemiBold, Geogrotesque"
            font-weight="600"
            letter-spacing="0.15em"
          >
            <tspan x="0" y="0">
              4
            </tspan>
          </text>
        </g>
        <path
          id="Path_654"
          data-name="Path 654"
          d="M1447.166,307.009c.438.021.876.046,1.314.061a2.205,2.205,0,0,1,2.315,1.808c.092,1.358,1.012,1.85,1.88,2.493a1.861,1.861,0,0,1,.635.688,2.131,2.131,0,0,1-.673-.192c-1.771-1.261-3.609-.763-5.4-.149a16.845,16.845,0,0,1-12.969-1c-.519-.239-1.052-.452-1.553-.724-.465-.252-1.232-.393-.8-1.2.368-.685.695-1.332,1.8-1.21a17.25,17.25,0,0,0,4.444-.225c-1.609-1.161-3.4-1.279-5.227-1.4a47.083,47.083,0,0,1-7.35-.782,36.109,36.109,0,0,1-13.12-5.989,13.346,13.346,0,0,1-4-4.516c1.7.739,3.13,1.4,4.589,1.994,4.233,1.732,8.463,3.5,13.045,4.1,2.418.316,4.854.492,7.285.7a16.594,16.594,0,0,1,9.228,4.165c.172.142.339.291.327.281.886-.764,1.756-1.44,2.541-2.205a34.117,34.117,0,0,1,10-6.929c4.315-1.919,8.643-3.845,12.79-6.093a99.769,99.769,0,0,0,8.425-5.649,5.2,5.2,0,0,1,.8-.561,25.5,25.5,0,0,1-6.19,7.061,76.243,76.243,0,0,1-13.842,9.058c-3.251,1.576-6.321,3.526-9.463,5.323a7.852,7.852,0,0,0-.942.744Z"
          transform="translate(-555.454 -97.744)"
          fill="#22ddcb"
        />
        <path
          id="Path_655"
          data-name="Path 655"
          d="M1516.724,590.774c-5.4-.994-10.921-3.145-15.27-7.612-2.688-2.76-5.234-5.663-7.775-8.562a67.932,67.932,0,0,0-13.023-11.806,5.509,5.509,0,0,0-5.078-.818c-2.2.618-4.7.286-7.06.156-1.978-.109-3.936-.553-5.909-.794-1.462-.178-2.126-1.049-1.936-2.707.158-1.377.912-1.764,2.309-2.014a39.426,39.426,0,0,0,6.474-2.1c.217-.079.333-.743.3-1.118a3.857,3.857,0,0,0-.45-1.387c-3.838-7.411-8.993-13.546-16.768-17.083-5.158-2.346-10.088-5.054-14.044-9.188a68.71,68.71,0,0,1-4.263-5.4l.408-.448c.49.257.992.493,1.466.776A62.235,62.235,0,0,0,1450.278,527c4.822,1.441,9.386,3.8,13.971,5.952,3.1,1.455,5.155,4.142,6.74,7.078,2.144,3.973,4.07,8.063,6.127,12.176a22.488,22.488,0,0,1,3.373.315,3.486,3.486,0,0,1,2.86,4.071,1.6,1.6,0,0,0,1.231,2.045,37.878,37.878,0,0,1,18.149,14.323c3.146,4.443,6.548,8.707,9.9,13.005C1514,587.739,1515.533,589.387,1516.724,590.774Z"
          transform="translate(-362.454 -319.474)"
          fill="#22ddcb"
        />
      </g>
      <g
        id="Group_361"
        data-name="Group 361"
        transform="translate(1571.152 -19.043)"
      >
        <g
          id="Group_343"
          data-name="Group 343"
          transform="translate(-607.009 297.401)"
        >
          <path
            id="Path_656"
            data-name="Path 656"
            d="M-482.718,346.146a14.644,14.644,0,0,0,.789-2.826c.4-5.223.379-10.414-1.733-15.363-.483-1.132-.981-2.26-1.391-3.419-.347-.981-.251-1.954.847-2.387a1.682,1.682,0,0,1,2.359.964,74.039,74.039,0,0,1,3.1,8.508,27.527,27.527,0,0,1-3.973,23.031c-.263.393-.456.928-.828,1.133a4.54,4.54,0,0,1-2.245.776c-1.038-.07-1.179-1.08-1.177-1.969.006-1.99-.01-3.982.065-5.97a18.148,18.148,0,0,0-3.556-11.056c-1.836.567-2.927-.665-4.059-1.839A20.607,20.607,0,0,0-504.057,330c.985,1.327,1.653,2.2,2.3,3.1,3.461,4.793,5.785,10.039,5.648,16.046-.132,5.769-2.366,10.851-5.814,15.418a20.871,20.871,0,0,1-2.194,2.592c-.7.663-1.712,1.221-2.521.377a3.075,3.075,0,0,1-.664-2.237c.247-2.055.748-4.079,1.111-6.122.85-4.794-.379-9.167-2.759-13.293-.331-.573-.763-1.094-1.044-1.688a1.661,1.661,0,0,1,.4-2.285,1.648,1.648,0,0,1,2.5.4,30.042,30.042,0,0,1,3.039,5.092,19.175,19.175,0,0,1,1.758,8.555,14.978,14.978,0,0,0,.175,1.774,15.711,15.711,0,0,0,2.036-6.311,21.03,21.03,0,0,0-5.076-16.615c-1.58-1.881-3.292-3.651-4.95-5.466-.693-.759-1.669-1.43-1-2.661a2.5,2.5,0,0,1,2.68-1.212c5.292.511,10.19,2.057,14.317,5.567.91.773,1.6,1.841,3.134,1.484.445-.1,1.134.556,1.622.972,3.417,2.914,4.989,6.81,5.828,11.087.1.507.217,1.01.326,1.515Z"
            transform="translate(511.334 -321.987)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_344"
          data-name="Group 344"
          transform="translate(-644.849 264.919)"
        >
          <path
            id="Path_657"
            data-name="Path 657"
            d="M-534.5,274.155c-4.984.591-8.334,2.627-10.424,6.724-1.228,2.408-2.7,2.428-4.173.125a36.032,36.032,0,0,0-8.032-9.108c-3.834-3.024-8.044-3.384-13.327-2.688,1.327.755,2.1,1.177,2.862,1.63,2.682,1.6,5.467,3.07,7.249,5.806,1.194,1.833,1.22,1.908.33,3.954,1.492,1.655,2.963,3.231,4.365,4.867.8.93,1.218,2.007.142,3.025-.759.718-2.052.512-2.946-.7a18.243,18.243,0,0,0-10.756-6.908,1.708,1.708,0,0,1-1.54-1.747,1.986,1.986,0,0,1,1.667-2.027,28.088,28.088,0,0,1,3.355-.372c-1.932-2.08-4.19-2.815-6.315-3.732-1.213-.524-2.569-.729-3.755-1.3a3.722,3.722,0,0,1-1.948-1.757c-.349-1.143.7-1.8,1.66-2.323,7.958-4.369,15.823-3.44,22.859,2.71,2.187,1.911,4.045,4.2,6.14,6.4.569-.53,1.141-1.013,1.655-1.552,4.222-4.422,9.508-5.1,15.2-4.335a2.385,2.385,0,0,1,1.076.344c.72.533,1.681,1.025,1.988,1.765.43,1.037-.573,1.663-1.4,2.168-.462.281-.929.555-1.7,1.011,1.069.314,1.781.545,2.505.733,4.943,1.276,8.088,4.708,10.6,8.874.571.948.758,2-.269,2.815-.84.664-1.866.242-2.823-.977a50.765,50.765,0,0,0-3.6-4.374,9.125,9.125,0,0,0-7.427-2.92c-1.038.079-2.073.224-3.112.24-1.758.027-2.636-1.165-1.994-2.824A35.03,35.03,0,0,1-534.5,274.155Z"
            transform="translate(577.816 -264.919)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_345"
          data-name="Group 345"
          transform="translate(-656.047 302.042)"
        >
          <path
            id="Path_658"
            data-name="Path 658"
            d="M-592.647,351.589c.8.254,1.51.465,2.207.7,1.808.6,2.3,1.314,1.817,3.186a18.269,18.269,0,0,0-.283,9.525,16.031,16.031,0,0,0,3.508,6.822c.87-2.922,1.637-5.7,2.542-8.436a22.767,22.767,0,0,1,1.6-3.309c.639-1.219,1.4-2.3,2.983-1.914.866-2.63,1.635-5.057,2.477-7.458a6.207,6.207,0,0,1,1.079-1.973c.591-.688,1.538-1.089,2.242-.348a4.115,4.115,0,0,1,.781,2.19c.055.394-.361.851-.554,1.283a29.144,29.144,0,0,0-2.511,9.851,24.169,24.169,0,0,1-.509,3.363c-.419,1.92-1.549,2.291-2.982,1.013a13.66,13.66,0,0,1-1.071-1.255c-1.5,2.934-1.793,5.912-2.2,8.856a12.764,12.764,0,0,1-.043,1.416c-.225.864-.324,1.975-.91,2.48-.9.776-2.019.147-2.84-.518a19.98,19.98,0,0,1-7.578-13.824,74.181,74.181,0,0,1-.041-7.456,12.088,12.088,0,0,0-1.725-.637c-2.953-.684-3.523-1.664-2.047-4.355a54.417,54.417,0,0,1,4.835-7.685c5.976-7.55,13.711-12.148,23.429-12.924a32.769,32.769,0,0,1,6.48.517,1.939,1.939,0,0,1,1.93,2.058c-.036,1.11-1.008,1.665-2.527,1.469a26.967,26.967,0,0,0-19.925,4.864,34.14,34.14,0,0,0-9.784,11.3A6.971,6.971,0,0,0-592.647,351.589Z"
            transform="translate(597.49 -330.14)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_346"
          data-name="Group 346"
          transform="translate(-660.92 286.585)"
        >
          <path
            id="Path_659"
            data-name="Path 659"
            d="M-599.342,311.77a22.523,22.523,0,0,1,3.146.879c1.359.623,1.531,1.607.581,2.72a4.44,4.44,0,0,1-1.069.924c-4.479,2.684-5.115,7.124-5.133,12a24.206,24.206,0,0,1,1.766-1.916c1.406-1.243,2.816-2.5,4.333-3.593a1.773,1.773,0,0,1,2.746.363c.675,1.079.182,1.935-.807,2.639a21.16,21.16,0,0,0-7.328,9.2c-.411.974-.922,1.681-2.062,1.536a2.256,2.256,0,0,1-1.946-2.134c-1.183-6.493-1.255-12.811,2.948-18.447.043-.057.005-.175.005-.531a9.257,9.257,0,0,1-1.988-.52c-.715-.356-1.678-.828-1.865-1.45a2.557,2.557,0,0,1,.858-2.126,47.354,47.354,0,0,1,14.546-8.11,5.549,5.549,0,0,1,1.678-.222c.924-.018,1.7.271,1.9,1.312a1.64,1.64,0,0,1-1.124,2.024c-.887.328-1.8.59-2.69.908A29.386,29.386,0,0,0-599.342,311.77Z"
            transform="translate(606.051 -302.985)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_347"
          data-name="Group 347"
          transform="translate(-612.772 289.276)"
        >
          <path
            id="Path_660"
            data-name="Path 660"
            d="M-491.118,317.439c-.336.493-.584,1.251-1.066,1.454a2.391,2.391,0,0,1-1.765-.327,3.687,3.687,0,0,1-1.117-1.259c-4.046-6.152-10.2-7.213-16.733-4.848-1.948.706-3.784,1.724-5.665,2.61a8.326,8.326,0,0,1-1.247.659c-.948.254-1.966.329-2.5-.706-.58-1.124-.1-1.973.944-2.6,5.209-3.146,10.677-5.318,16.934-4.553,5.071.621,8.659,3.525,11.477,7.555A8.984,8.984,0,0,1-491.118,317.439Z"
            transform="translate(521.46 -307.711)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_348"
          data-name="Group 348"
          transform="translate(-625.665 309.433)"
        >
          <path
            id="Path_661"
            data-name="Path 661"
            d="M-541.176,343.125c8.184.725,13.482,4.091,16.007,12.82a3.635,3.635,0,0,1-.079,2.2,1.679,1.679,0,0,1-2.984-.057,11.4,11.4,0,0,1-1.018-2.637,11.858,11.858,0,0,0-11.534-8.411c-.19,0-.38.005-.568-.006-1.769-.1-2.773-.763-2.758-1.811C-544.094,344.043-542.828,343.14-541.176,343.125Z"
            transform="translate(544.111 -343.125)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_349"
          data-name="Group 349"
          transform="translate(-636.67 286.043)"
        >
          <path
            id="Path_662"
            data-name="Path 662"
            d="M-561.319,302.033a18.467,18.467,0,0,1,9.6,4.711c1.839,1.746,3.385,3.8,5.061,5.717a1.572,1.572,0,0,1,.155.237c.9,1.508.871,2.7-.071,3.289-.965.6-2.08.261-3.058-1.208-2.353-3.534-5.164-6.523-9.182-8.151-1.045-.423-2.155-.685-3.219-1.065a1.8,1.8,0,0,1-1.37-2.17C-563.174,302.207-562.273,302.006-561.319,302.033Z"
            transform="translate(563.445 -302.031)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_350"
          data-name="Group 350"
          transform="translate(-624.696 368.548)"
        >
          <path
            id="Path_663"
            data-name="Path 663"
            d="M-539.77,446.985c2.95.253,5.388.454,7.824.682a3.824,3.824,0,0,1,1.082.311c.827.344,1.693.889,1.315,1.85-.263.669-1.078,1.162-1.726,1.619a1.789,1.789,0,0,1-1.11.069c-2.705-.333-5.407-.694-8.112-1.036-1.169-.147-2.031-.632-1.9-1.963.136-1.366,1.178-1.541,2.291-1.534C-539.822,446.987-539.539,446.985-539.77,446.985Z"
            transform="translate(542.409 -446.985)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_351"
          data-name="Group 351"
          transform="translate(-624.733 361.731)"
        >
          <path
            id="Path_664"
            data-name="Path 664"
            d="M-533.986,439.537c-1.918-.282-4.344-.614-6.761-1-1.181-.19-1.912-.86-1.686-2.157.209-1.2,1.155-1.442,2.153-1.352,2.44.22,4.888.426,7.3.819,1.9.309,2.552,1.052,2.41,2.2S-531.477,439.587-533.986,439.537Z"
            transform="translate(542.474 -435.007)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_352"
          data-name="Group 352"
          transform="translate(-625.069 354.901)"
        >
          <path
            id="Path_665"
            data-name="Path 665"
            d="M-534.5,427.57c-2.221-.242-4.286-.455-6.346-.7a1.846,1.846,0,0,1-1.053-.369c-.484-.5-1.2-1.147-1.162-1.682.044-.579.778-1.191,1.342-1.611a2.093,2.093,0,0,1,1.375-.158c2.242.3,4.477.651,6.716.968,1.14.162,2.03.608,2.046,1.91.014,1.06-.869,1.6-2.509,1.643C-534.282,427.574-534.471,427.57-534.5,427.57Z"
            transform="translate(543.065 -423.008)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_353"
          data-name="Group 353"
          transform="translate(-625.192 348.122)"
        >
          <path
            id="Path_666"
            data-name="Path 666"
            d="M-535.151,415.617c-2.061-.227-4.129-.405-6.18-.7a1.967,1.967,0,0,1-1.949-2.04,2.12,2.12,0,0,1,2.494-1.765c2.244.26,4.466.713,6.706,1.02,1.158.159,2.072.522,2,1.873-.074,1.38-1.089,1.609-2.22,1.61C-534.583,415.618-534.867,415.618-535.151,415.617Z"
            transform="translate(543.281 -411.099)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_354"
          data-name="Group 354"
          transform="translate(-625.314 341.386)"
        >
          <path
            id="Path_667"
            data-name="Path 667"
            d="M-536.525,403.7c-1.5-.206-3.379-.4-5.231-.741a1.806,1.806,0,0,1-1.7-2.187,1.907,1.907,0,0,1,2.355-1.484c2.15.272,4.285.658,6.434.936,1.187.154,2.041.607,1.938,1.931-.108,1.385-1.162,1.55-2.277,1.545C-535.388,403.7-535.767,403.7-536.525,403.7Z"
            transform="translate(543.495 -399.264)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_355"
          data-name="Group 355"
          transform="translate(-625.518 334.685)"
        >
          <path
            id="Path_668"
            data-name="Path 668"
            d="M-536.644,391.9c-1.765-.244-3.529-.495-5.3-.73a1.954,1.954,0,0,1-1.914-2.043c-.015-1.079,1.08-1.781,2.495-1.6,1.767.222,3.528.5,5.283.811,1.739.3,2.624,1.05,2.566,2.067C-533.574,391.538-534.589,392-536.644,391.9Z"
            transform="translate(543.853 -387.491)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_356"
          data-name="Group 356"
          transform="translate(-625.729 327.934)"
        >
          <path
            id="Path_669"
            data-name="Path 669"
            d="M-541.755,375.658c1.829.269,3.693.494,5.537.831a1.9,1.9,0,0,1,1.71,2.176,1.822,1.822,0,0,1-2.092,1.492c-1.951-.239-3.895-.575-5.822-.97-1.092-.224-2-.9-1.765-2.141C-543.955,375.825-542.935,375.525-541.755,375.658Z"
            transform="translate(544.224 -375.629)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_357"
          data-name="Group 357"
          transform="translate(-625.815 321.285)"
        >
          <path
            id="Path_670"
            data-name="Path 670"
            d="M-541.118,363.948c1.127.2,2.529.389,3.894.725a1.989,1.989,0,0,1,1.721,2.214,2.209,2.209,0,0,1-2.672,1.488c-1.481-.257-2.935-.66-4.412-.939-1.185-.224-1.948-.78-1.759-2.08.172-1.188,1.092-1.406,2.108-1.408Z"
            transform="translate(544.374 -363.948)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_358"
          data-name="Group 358"
          transform="translate(-488.515 387.863)"
        >
          <path
            id="Path_671"
            data-name="Path 671"
            d="M-296.449,484.478c-1.544,0-3.246-.049-5.088-.146a1.707,1.707,0,0,1-1.614-1.8,1.706,1.706,0,0,1,1.8-1.615c2.741.145,5.148.18,7.154.1a1.713,1.713,0,0,1,1.772,1.639,1.708,1.708,0,0,1-1.639,1.773Q-295.185,484.478-296.449,484.478Z"
            transform="translate(303.153 -480.919)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_359"
          data-name="Group 359"
          transform="translate(-675.449 376.092)"
        >
          <path
            id="Path_672"
            data-name="Path 672"
            d="M-452.842,474.589c-.063,0-.125,0-.188-.01-4.038-.444-7.739-.955-10.133-1.305-2.154-.316-3.6-.553-4-.619a97.541,97.541,0,0,1-10.146-1.086c-3.137-.464-6.495-1.033-9.656-1.578l-3.3-.573c-13.639-2.37-29.1-5.057-41.521-4.658-4.43.142-8.939.381-13.3.614-6.654.353-13.535.718-20.336.751-12.425.071-25.024-.772-37.2-1.576-3.341-.22-6.681-.441-10.019-.644a67.591,67.591,0,0,0-9.345-.135c-2.413.193-4.9.5-7.609.928a1.708,1.708,0,0,1-1.955-1.417,1.708,1.708,0,0,1,1.418-1.956c2.8-.446,5.372-.76,7.875-.959a69.667,69.667,0,0,1,9.824.13q5.017.3,10.036.645c12.119.8,24.655,1.632,36.961,1.568,6.719-.032,13.558-.394,20.172-.746,4.378-.233,8.9-.474,13.371-.616,12.768-.417,28.414,2.307,42.216,4.706l3.3.572c3.14.541,6.473,1.1,9.575,1.564a93.226,93.226,0,0,0,9.884,1.056,1.764,1.764,0,0,1,.219.023c.016,0,1.58.265,4.038.626,2.366.347,6.024.852,10.01,1.289a1.707,1.707,0,0,1,1.511,1.883A1.708,1.708,0,0,1-452.842,474.589Z"
            transform="translate(631.577 -460.239)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_360"
          data-name="Group 360"
          transform="translate(-733.411 378.566)"
        >
          <path
            id="Path_673"
            data-name="Path 673"
            d="M-729.064,474.536c-1.62,0-2.565-.035-2.709-.04a1.707,1.707,0,0,1-1.638-1.774,1.723,1.723,0,0,1,1.774-1.639A167.214,167.214,0,0,0-699,468.264c1.8-.327,3.7-.7,5.963-1.183,1.718-.364,3.425-.738,5.127-1.112,2.046-.45,4.162-.915,6.25-1.348a1.7,1.7,0,0,1,2.018,1.326,1.707,1.707,0,0,1-1.326,2.018c-2.068.429-4.174.891-6.21,1.339-1.711.376-3.427.753-5.153,1.118-2.3.486-4.224.868-6.059,1.2A176.89,176.89,0,0,1-729.064,474.536Z"
            transform="translate(733.411 -464.585)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);
