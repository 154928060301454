import React from 'react';
// down
export default ({ className, ...other }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26.919 32.445"
    {...other}
    className={`icon w-content ${className || ''}`}
  >
    <g
      xmlns="http://www.w3.org/2000/svg"
      id="Symbol_1_1"
      data-name="Symbol 1 – 1"
      transform="translate(-812.69 -2058.554)"
    >
      <path
        id="Path_345"
        data-name="Path 345"
        d="M7392.348,328.138q-.885,0-1.773,0h-.847c-.585,0-.7-.136-.7-.808v-22c0-.63-.125-.775-.671-.775H7372.11c-.536,0-.66.139-.66.745v27.342c0,.642.112.771.667.771h7.893v1.131c0,.624,0,1.225,0,1.825,0,.428.169.627.529.627q5.907.007,11.813,0c.377,0,.532-.177.532-.611q0-3.827,0-7.654C7392.884,328.3,7392.739,328.139,7392.348,328.138Zm-19.884-22.392a.617.617,0,0,1,.51-.188h0q5.51.012,11.02.007h2.563l.328,0c.246,0,.5,0,.749,0a.484.484,0,0,1,.4.152.683.683,0,0,1,.143.456c0,1.414,0,2.585,0,3.685a.65.65,0,0,1-.148.459.465.465,0,0,1-.356.143l-.054,0c-.044,0-.091,0-.136,0h-14.393c-.11,0-.221-.007-.34-.015l-.171-.011-.254-.014v-2.047c0-.2,0-.406,0-.609,0-.475.007-.967-.01-1.445A.833.833,0,0,1,7372.464,305.746Zm7.546,23.086v.634c0,.752,0,1.529,0,2.293a.719.719,0,0,1-.14.486.52.52,0,0,1-.416.164h0c-2.094-.006-4.338-.01-6.567,0h-.006a.521.521,0,0,1-.418-.166.756.756,0,0,1-.136-.529v-.05q0-7.729,0-15.458V311.5l.248-.021.142-.014a2.8,2.8,0,0,1,.29-.02q7.247,0,14.495,0c.559,0,.68.14.68.786q0,7.551,0,15.1c0,.669-.121.8-.717.8h-6.84C7380.148,328.138,7380.011,328.294,7380.01,328.832Zm11.858,7a.554.554,0,0,1-.46.169c-3.367-.01-6.7-.01-9.909,0h-.005a.578.578,0,0,1-.464-.173.8.8,0,0,1-.149-.54c.009-1.362.008-2.748.007-4.088v-2.046h11.087l.021.4c.005.085.011.166.011.248q0,.789,0,1.577c0,1.29,0,2.625.008,3.936A.78.78,0,0,1,7391.869,335.837Z"
        transform="translate(-6558.76 1753.998)"
        fill="currentColor"
      />
      <path
        id="Path_346"
        data-name="Path 346"
        d="M7500.811,304.89l-1.754,0-1.709,0c-.422,0-.579.183-.579.67v.473q0,10.174,0,20.347a1.4,1.4,0,0,0,.1.525c.364.861.743,1.733,1.108,2.576q.311.717.621,1.435c.155.362.3.438.477.438h.009c.2,0,.318-.113.457-.438.227-.528.456-1.055.683-1.582.351-.808.7-1.617,1.044-2.428a1.388,1.388,0,0,0,.107-.522q.01-4.233.006-8.466V305.563C7501.389,305.073,7501.232,304.891,7500.811,304.89Zm-1.713,24.86-1.187-2.663h2.319Zm1.412-19.116-.023-.049v15.45h-2.818V308.6l.005.014v-2.72h1.552c.268,0,.545,0,.82.01a.524.524,0,0,1,.455.465c.01.841.009,1.694.009,2.52Z"
        transform="translate(-6661.78 1753.734)"
        fill="currentColor"
      />
      <ellipse
        id="Ellipse_21"
        data-name="Ellipse 21"
        cx="0.556"
        cy="0.636"
        rx="0.556"
        ry="0.636"
        transform="translate(815.546 2061.393)"
        fill="currentColor"
      />
      <ellipse
        id="Ellipse_22"
        data-name="Ellipse 22"
        cx="0.556"
        cy="0.636"
        rx="0.556"
        ry="0.636"
        transform="translate(836.743 2061.393)"
        fill="currentColor"
      />
      <ellipse
        id="Ellipse_23"
        data-name="Ellipse 23"
        cx="0.556"
        cy="0.636"
        rx="0.556"
        ry="0.636"
        transform="translate(823.408 2084.451)"
        fill="currentColor"
      />
    </g>
  </svg>
);
