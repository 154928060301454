import React from "react";

export default ({ className, ...other }) => (
  <svg
    {...other}
    className={`icon w16 ${className || ""}`}
    viewBox="0 0 496 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M248 167.5l64.9 98.8H183.1l64.9-98.8zM496 256c0 136.9-111.1 248-248 248S0 392.9 0 256 111.1 8 248 8s248 111.1 248 248zm-99.8 82.7L248 115.5 99.8 338.7h30.4l33.6-51.7h168.6l33.6 51.7h30.2z"
      className=""
    />
  </svg>
);
