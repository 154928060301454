import React from 'react';

export default ({ className, ...other }) => (
  <svg
    {...other}
    className={`icon w-bars ${className || ''}`}
    viewBox="0 0 23 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      xmlns="http://www.w3.org/2000/svg"
      id="Symbol_2_1"
      data-name="Symbol 2 – 1"
      transform="translate(-376 -104)"
    >
      <rect
        id="Rectangle_3"
        data-name="Rectangle 3"
        width="23"
        height="3"
        transform="translate(376 104)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_4"
        data-name="Rectangle 4"
        width="23"
        height="3"
        transform="translate(376 116)"
        fill="currentColor"
      />
    </g>
  </svg>
);
