
import React from 'react';
import Layout from '@/layout';
import ReactGA from 'react-ga';
import smoothscroll from 'smoothscroll-polyfill';

// styles
import 'styles/main.scss';

// polyfills
const isServer = typeof window === 'undefined';
if (!isServer) {
  // trigger polyfill in client only so build doesnt break
  smoothscroll.polyfill();
  // initialize GA
  ReactGA.initialize('UA-140790005-1');
}

export const wrapRootElement = ({ element }) => (
  <>{element}</>
);
export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

export const onRouteUpdate = (state) => {
  ReactGA.pageview(state.location.pathname);
};
