import React, { createContext, useState } from 'react';
import useScrollPos from 'hooks/useScrollPosition';

export const layoutContext = createContext();
const { Provider } = layoutContext;

export default function LayoutProvider({ children }) {
  const [height, setHeight] = useState(null);
  const scrollPos = useScrollPos(height);
  return (
    <Provider value={{ ...scrollPos, height, setHeight }}>
      {children}
    </Provider>
  );
}
