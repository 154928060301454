import { useEffect, useState } from 'react';

export default function useScrollPosition(height) {
  const [pos, setPos] = useState(null);
  const [scrollDepth, setScrollDepth] = useState({});
  const handleScrollPos = () => setPos(window.scrollY);
  useEffect(() => {
    window.addEventListener('scroll', handleScrollPos);
    return () => window.removeEventListener('scroll', handleScrollPos);
  }, []);
  useEffect(() => {
    if (Number.isInteger(pos)) {
      const currentHeight = height || window.innerHeight;
      const quarter = pos >= 0.25 * currentHeight;
      const half = pos >= 0.5 * currentHeight;
      const threeQuarters = pos >= 0.75 * currentHeight;
      const end = pos >= 1 * currentHeight;
      setScrollDepth({ quarter, half, threeQuarters, end });
    }
  }, [pos]);
  return { pos, ...scrollDepth };
}
