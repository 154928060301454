import React, { useState, useContext } from 'react';
import { layoutContext } from 'context/layout-provider';
import Icon from 'icon';
import { Link } from 'gatsby';
import Nav from './nav';
import s from './header.module.scss';

export default function Header({ pathname }) {
  const [active, setActive] = useState(null);
  const handleNav = () => setActive(!active);
  const { quarter, threeQuarters, end } = useContext(layoutContext);
  const quarterNoEnd = quarter && !end && s.quarterNoEnd;
  const quarterAndEnd = quarter && end && s.quarterAndEnd;
  const positionClass = quarterNoEnd || quarterAndEnd || '';
  const isHome = pathname === '/';

  function handleTalk(e) {
    if (window.innerWidth < 768) {
      e.stopPropagation();
      window.location.href = 'tel:8332255296';
    }
  }
  return (
    <header
      className={`${s.header} ${positionClass} ${
        threeQuarters ? s.threeQuarters : ''
      } ${isHome ? s.isHome : ''}`}
    >
      <div className="container">
        <nav>
          <div className={s.navTextWrapper}>
            <button
              type="button"
              className={`${isHome ? s.isHome : ''} ${s.trigger}`}
              onClick={handleNav}
            >
              <Icon className={s.bars} icon="bars" />
              menu
            </button>
          </div>
          <Link className={s.logo} to="/">
            <img src="/svg/logo-color.svg" alt="logo" className={s.bigLogo} />
            <Icon icon="logo" className={s.smallLogo} />
          </Link>
          <div className={`${s.navTextWrapper} ${s.right}`}>
            <div onClick={e => handleTalk(e)}>
              <Link
                className={`${isHome ? s.isHome : ''} ${s.contact}`}
                to="/contact"
              >
                Let's talk!
              </Link>
            </div>
          </div>
          <Nav handleNav={handleNav} active={active} />
        </nav>
      </div>
    </header>
  );
}
