/* eslint jsx-a11y/anchor-has-content: 0 */
/* eslint react/button-has-type: 0 */
import React from 'react';
import { Link } from 'gatsby';
import { scrollto } from 'utils/scrollto';
import { Location } from '@reach/router';
import ReactGA from 'react-ga';
import s from './button.module.scss';

export default ({
  href,
  native,
  popup_trigger,
  children,
  className,
  secondary,
  big,
  scroll,
  small,
  type,
  html,
  reg,
  white,
  hoverWhite,
  bordersOn,
  onClick,
  action,
  category,
  label,
  ...props
}) => {
  const secondaryClasses = secondary ? s.secondary : '';
  const bigClass = big ? s.big : '';
  const smallClass = small ? s.small : '';
  const sizeClasses = bigClass || smallClass;
  const classes = `${s.default} ${className ||
    ''} ${secondaryClasses} ${sizeClasses} ${reg ? s.reg : ''} ${
    white ? s.white : ''
  } ${hoverWhite ? s.hoverWhite : ''} ${bordersOn ? s.bordersOn : ''}`;
  const childrenProps = html
    ? {
        dangerouslySetInnerHTML: { __html: html }
      }
    : {
        children
      };
  if (scroll) {
    return (
      <Location>
        {({ location }) => (
          <button
            type={type || 'button'}
            onClick={e => {
              ReactGA.event({
                category: 'Button Click',
                action: action || 'scroll to section',
                label: label
                ? `${label} ${location.pathname}`
                : `button tag ${location.pathname}`
              });
              scrollto(scroll);
              onClick && onClick(e);
            }}
            className={classes}
            {...props}
          >
            <span className={s.lineHelper}>
              <span className={s.topLine} {...childrenProps} />
            </span>
          </button>
        )}
      </Location>
    );
  }
  if (!popup_trigger && href && native) {
    return (
      <Location>
        {({ location }) => {
          const clickEvents = {
            onClick: e => {
              ReactGA.event({
                category: category || 'Button Click',
                action: action || 'outbound link',
                label: label
                  ? `${label} ${location.pathname}`
                  : `anchor tag ${location.pathname}`
              });
              onClick && onClick(e);
            }
          };
          return (
            <a className={classes} href={href} {...props} {...clickEvents}>
              <span className={s.lineHelper}>
                <span className={s.topLine} {...childrenProps} />
              </span>
            </a>
          );
        }}
      </Location>
    );
  }
  if (!popup_trigger && href) {
    return (
      <Location>
        {({ location }) => {
          const clickEvents = {
            onClick: e => {
              ReactGA.event({
                category: category || 'Button Click',
                action: action || 'navigation',
                label: label
                  ? `${label} ${location.pathname}`
                  : `anchor tag ${location.pathname}`
              });
              onClick && onClick(e);
            }
          };
          return (
            <Link {...props} to={href} className={classes} {...clickEvents}>
              <span className={s.lineHelper}>
                <span className={s.topLine} {...childrenProps} />
              </span>
            </Link>
          );
        }}
      </Location>
    );
  }
  return (
    <Location>
      {({ location }) => {
        const clickEvents = {
          onClick: e => {
            ReactGA.event({
              category: category || 'Button Click',
              action: action || 'Button Click',
              label: label
                ? `${label} ${location.pathname}`
                : `button tag ${location.pathname}`
            });
            onClick && onClick(e);
          }
        };
        return (
          <button
            type={type || 'button'}
            {...props}
            className={classes}
            {...clickEvents}
          >
            <span className={s.lineHelper}>
              <span className={s.topLine} {...childrenProps} />
            </span>
          </button>
        );
      }}
    </Location>
  );
};
