import React from 'react';
// down
export default ({ className, ...other }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28.121 30.827"
    {...other}
    className={`icon w-digital ${className || ''}`}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      id="Path_688"
      data-name="Path 688"
      d="M392.666,266.686l1.517-2.3a.444.444,0,0,0,.014-.468l-1.354-2.345a.453.453,0,0,0-.413-.222l-2.748.165-2.57-4.451a1.783,1.783,0,0,0-1.536-.9h-.023a1.79,1.79,0,0,0-1.515.83l-.013.018-8.872,11.3-5.789,3.342a4.51,4.51,0,0,0-1.65,6.158,4.529,4.529,0,0,0,5.577,1.932l.187-.075,3.51,6.08a2.476,2.476,0,1,0,4.29-2.476l-2.147-3.72,1.96-1.131a.446.446,0,0,0,.163-.608l-1.043-1.806.319-.062,6.458-1.267,6.543-1.281a1.8,1.8,0,0,0,1.458-2.7Zm-.457-4.428,1.079,1.869-1.12,1.7-1.988-3.443ZM373.427,278.7a3.62,3.62,0,0,1-4.941-1.324h0a3.615,3.615,0,0,1,1.324-4.941l5.478-3.163,3.617,6.265Zm6.275,3.635.769,1.331.036.062.015.027.095.166-.012.007a1.587,1.587,0,0,1-1.467,2.178,1.6,1.6,0,0,1-.419-.056,1.577,1.577,0,0,1-.959-.737l-3.5-6.065,2.747-1.586Zm.557-4.452-1.574.909-.909-1.574,1.574-.909Zm-.519-2.68-3.753-6.5,7.885-10.046,8.16,14.134Zm14.482-3.147a.9.9,0,0,1-.77.464.773.773,0,0,0-.154.024l-.181.035-.161.03-8.49-14.706.23-.295a1.085,1.085,0,0,0,.083-.111.909.909,0,0,1,1.563.019l7.875,13.641A.9.9,0,0,1,394.222,272.051Z"
      transform="translate(-367.11 -256.167)"
      fill="currentColor"
    />
  </svg>
);
