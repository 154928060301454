import React from 'react';

export default ({ className, ...other }) => (
  <svg
    {...other}
    className={`icon w-web-app ${className || ''}`}
    viewBox="0 0 28.358 30.989"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path_339"
      data-name="Path 339"
      d="M6985.786,568.323a2.027,2.027,0,0,0-2.129-1.718q-11.964,0-23.927,0a2.4,2.4,0,0,0-.677.067,2.294,2.294,0,0,0-1.54,2.419q0,10.24,0,20.48a4.14,4.14,0,0,0,.081.786,2.145,2.145,0,0,0,1.892,1.7c1.888-.014,3.807-.011,5.661-.007l2.085,0c.089,0,.178.006.271.012l.392.022-.014.308c0,.029,0,.053,0,.073a.682.682,0,0,1-.021.189l-.138.482c-.286,1-.582,2.033-.885,3.046a.622.622,0,0,1-.489.4,4.131,4.131,0,0,1-.454.018,3.872,3.872,0,0,0-.39.014c-.312.033-.343.339-.343.469a.447.447,0,0,0,.352.483,2.644,2.644,0,0,0,.372.016q5.812,0,11.588,0h.028a3.181,3.181,0,0,0,.337-.012.445.445,0,0,0,.382-.482.418.418,0,0,0-.365-.479,4.672,4.672,0,0,0-.717-.013c-.33.018-.544-.159-.65-.54-.2-.736-.412-1.469-.622-2.2l-.294-1.033c-.021-.076-.038-.153-.058-.248-.008-.044-.019-.092-.03-.146l-.083-.38h2.271q3,0,6,0h.081a1.986,1.986,0,0,0,.515-.041,2.308,2.308,0,0,0,1.6-2.434q0-10.24,0-20.48A3.694,3.694,0,0,0,6985.786,568.323Zm-27.4.859c0-1.1.421-1.587,1.368-1.588q11.933,0,23.866,0c.945,0,1.365.49,1.366,1.589V584.63c0,.681-.119.819-.705.819h-25.162c-.61,0-.733-.138-.733-.821Zm17.174,26.776a.916.916,0,0,1,.03.184c0,.024,0,.051.008.082l.041.347h-7.957l.115-.506c.018-.082.033-.153.054-.221q.144-.494.291-.988c.215-.732.438-1.489.648-2.235.112-.395.318-.572.668-.572h.01c1.3.012,2.622.009,3.9.006h.552a.567.567,0,0,1,.626.453c.223.776.452,1.551.68,2.326Q6975.4,595.4,6975.564,595.958Zm9.426-6.312c0,.868-.421,1.377-1.178,1.434-.064,0-.129.006-.194.006h-23.712a3.433,3.433,0,0,1-.484-.024,1.2,1.2,0,0,1-1.025-1.249c-.01-.569-.009-1.145-.009-1.7,0-.365,0-.729,0-1.093a.6.6,0,0,1,.139-.428.451.451,0,0,1,.387-.128,1.361,1.361,0,0,0,.138,0h25.266c.553,0,.672.137.673.773v.6C6984.992,588.438,6984.993,589.042,6984.99,589.646Z"
      transform="translate(-6957.512 -566.603)"
      fill="currentColor"
    />
    <path
      id="Path_340"
      data-name="Path 340"
      d="M6975.2,588.426a1.093,1.093,0,0,0,.148,0h20.979a1.252,1.252,0,0,0,.254-.015.452.452,0,0,0,.385-.51c0-1.226,0-2.364,0-3.479a.415.415,0,0,0-.4-.449c-.078-.006-.156-.005-.236,0h-19.243q-.933,0-1.867,0c-.336,0-.473.161-.474.55q0,1.671,0,3.342C6974.753,588.218,6974.894,588.391,6975.2,588.426Zm.443-3.484h20.419v2.465h-20.419Z"
      transform="translate(-6971.683 -580.435)"
      fill="currentColor"
    />
    <path
      id="Path_341"
      data-name="Path 341"
      d="M7024.593,618.228h0a2.934,2.934,0,0,0-2.722,3.1,3.369,3.369,0,0,0,.818,2.228,2.53,2.53,0,0,0,1.9.914h.012a2.973,2.973,0,0,0,2.724-3.155A2.932,2.932,0,0,0,7024.593,618.228Zm1.294,4.6a1.715,1.715,0,0,1-1.286.622h-.009a2.044,2.044,0,0,1-1.845-2.141,2.317,2.317,0,0,1,.56-1.509,1.7,1.7,0,0,1,1.295-.626,2.042,2.042,0,0,1,1.846,2.144A2.32,2.32,0,0,1,7025.887,622.833Z"
      transform="translate(-7010.418 -607.718)"
      fill="currentColor"
    />
    <path
      id="Path_342"
      data-name="Path 342"
      d="M6974.762,621.319a2.962,2.962,0,0,0,2.717,3.151h0a2.547,2.547,0,0,0,1.92-.926,3.359,3.359,0,0,0,.814-2.221,2.935,2.935,0,0,0-2.732-3.093h0A2.893,2.893,0,0,0,6974.762,621.319Zm2.71-2.141a1.717,1.717,0,0,1,1.3.628,2.311,2.311,0,0,1,.567,1.5,2.053,2.053,0,0,1-1.847,2.146h-.019a1.693,1.693,0,0,1-1.27-.607,2.33,2.33,0,0,1-.562-1.523A2.017,2.017,0,0,1,6977.472,619.178Z"
      transform="translate(-6971.692 -607.719)"
      fill="currentColor"
    />
    <path
      id="Path_343"
      data-name="Path 343"
      d="M7071.71,618.229h0A2.941,2.941,0,0,0,7069,621.31a3.316,3.316,0,0,0,.791,2.2,2.6,2.6,0,0,0,1.963.956h.009a2.487,2.487,0,0,0,1.868-.9,3.424,3.424,0,0,0,.822-2.233A2.909,2.909,0,0,0,7071.71,618.229Zm.086,5.231h0a1.839,1.839,0,0,1-1.384-.663,2.26,2.26,0,0,1-.537-1.514,2.044,2.044,0,0,1,1.866-2.106h.021a2.175,2.175,0,0,1,.037,4.282Z"
      transform="translate(-7049.159 -607.719)"
      fill="currentColor"
    />
    <path
      id="Path_344"
      data-name="Path 344"
      d="M7035.467,673.6a.422.422,0,0,0-.29-.147h-.013a.535.535,0,0,0-.419.508.474.474,0,0,0,.435.494h.01a.415.415,0,0,0,.308-.157.539.539,0,0,0,.122-.355h0A.544.544,0,0,0,7035.467,673.6Z"
      transform="translate(-7021.003 -651.697)"
      fill="currentColor"
    />
  </svg>
);