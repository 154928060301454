/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import data from '@/layout/data';
import { Link } from 'gatsby';
import Icon from 'icon';
import s from './footer.module.scss';

function NavWrapper({ children, className }) {
  return <div className={`${s.navWrapper} ${className || ''}`}>{children}</div>;
}

export default function Footer() {
  const year = new Date();
  return (
    <footer className={s.footer}>
      <div className="container">
        <div className={s.wrapperIntro}>
          <div className={s.logoWrapper}>
            <img src="/svg/logo-grey.svg" alt="logo" className={s.logo} />
            <br />
            <p className={s.smallText}>
              <span>HERE’S TO THE</span>
              <span>CRAZY ONES.</span>
            </p>
          </div>
        </div>
        <div className={s.wrapperLinks}>
          <NavWrapper className={s.customOrder}>
            <div className={s.logoWrapper}>
              <img src="/svg/logo-grey.svg" alt="logo" className={s.logo} />
              <br />
              <p className={s.smallText}>
                <span>HERE’S TO THE</span>
                <span>CRAZY ONES.</span>
              </p>
            </div>
            <p className={`${s.smallText} ${s.transparent}`}>
              <span>&copy; {year.getFullYear()} CONNECTIONS COLLECTIVE INC.</span>
              <span>All Rights Reserved</span>
            </p>
          </NavWrapper>
          <NavWrapper>
            <div>
              <p className={s.navTitle}>office</p>
              <p className={`${s.navLink} ${s.noUnderline}`}>
                <span>Miami -</span>
                <span>1815 Purdy Ave,</span>
                <span>Miami Beach, FL 33139</span>
              </p>
            </div>
            {/* <Link to="/terms-and-conditions" className={s.legalLink}>
              Terms and conditions
            </Link> */}
            <Link to="/privacy-policy" className={s.legalLink}>
              privacy policy
            </Link>
          </NavWrapper>
          <NavWrapper>
            <div>
              <p className={s.navTitle}>Start a conversation</p>
              <a
                href="mailto:team@cxncollective.com"
                className={`${s.navLink} ${s.noCapital}`}
              >
                info@cxncollective.com
              </a>
              <br />
              <a
                href="tel:8332255296"
                className={`${s.navLink} ${s.noUnderline} ${s.custom}`}
              >
                +1 833-CALL-CXN
                <span className={s.extraNumber}>
                  2255-<span>296</span>
                </span>
              </a>
            </div>
          </NavWrapper>
          <NavWrapper>
            <div>
              <p className={s.navTitle}>General</p>
              <ul>
                {data.pages.map(({ text, href }) => {
                  return (
                    <li key={text}>
                      <Link to={href} className={s.navLink}>
                        {text}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </NavWrapper>
          <NavWrapper>
            <div>
              <p className={s.navTitle}>Social</p>
              <ul>
                {data.social.map(({ text, href }) => {
                  return (
                    <li key={text} className={s.socialItem}>
                      <a
                        href={href}
                        className={s.navLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {
                          <>
                            <span className={s.socialText}>{text}</span>
                            <span className={s.socialIcon}>
                              <Icon icon={text.toLowerCase()} />
                            </span>
                          </>
                        }
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </NavWrapper>
        </div>
      </div>
    </footer>
  );
}
