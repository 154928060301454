import React from 'react';

export default ({ className, ...other }) => (
  <svg
    {...other}
    className={`icon w-caret ${className || ''}`}
    viewBox="0 0 10.288 31.97"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M-80.707,70.575V99.059A1.739,1.739,0,0,1-84,99.844l-6.557-12.969a4.087,4.087,0,0,1-.024-3.64l6.57-13.426A1.74,1.74,0,0,1-80.707,70.575Z"
      transform="translate(90.995 -68.832)"
      fill="currentColor"
    />
  </svg>
);
