import React from 'react';

export default function ClockCxn({ className, ...other }) {
  return (
    <svg
      {...other}
      className={`icon w16 my-svg ${className || ''}`}
      viewBox="0 0 131.284 131.293"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="aNb1E3.tif" transform="translate(0 0)">
        <g id="Group_20" data-name="Group 20" transform="translate(0 0)">
          <path
            id="Path_266"
            data-name="Path 266"
            d="M6006.556,731.819h4.542v13.658h-4.542Z"
            transform="translate(-5943.19 -620.638)"
            fill="#2f303c"
          />
          <path
            id="Path_267"
            data-name="Path 267"
            d="M6215.33,522.54H6229v4.533H6215.33Z"
            transform="translate(-6104.28 -459.158)"
            fill="#2f303c"
          />
          <path
            id="Path_268"
            data-name="Path 268"
            d="M5771,527.153h-13.664v-4.532H5771Z"
            transform="translate(-5750.893 -459.221)"
            fill="#2f303c"
          />
          <path
            id="Path_269"
            data-name="Path 269"
            d="M6011.125,287.517h-4.512V273.846h4.512Z"
            transform="translate(-5943.234 -267.266)"
            fill="#2f303c"
          />
          <path
            id="Path_270"
            data-name="Path 270"
            d="M5882.111,714.121l-3.982-2.3,6.865-11.9,3.982,2.3Z"
            transform="translate(-5844.096 -596.023)"
            fill="#2f303c"
          />
          <path
            id="Path_271"
            data-name="Path 271"
            d="M5885,317.109l-6.864-11.9,3.978-2.305,6.866,11.9Z"
            transform="translate(-5844.103 -289.688)"
            fill="#2f303c"
          />
          <path
            id="Path_272"
            data-name="Path 272"
            d="M6111.11,317.5l-3.982-2.3,6.864-11.9,3.983,2.3C6115.682,309.571,6113.416,313.5,6111.11,317.5Z"
            transform="translate(-6020.792 -289.992)"
            fill="#2f303c"
          />
          <path
            id="Path_273"
            data-name="Path 273"
            d="M6107.128,701.841l3.981-2.3,6.863,11.895-3.977,2.3Z"
            transform="translate(-6020.792 -595.733)"
            fill="#2f303c"
          />
          <path
            id="Path_274"
            data-name="Path 274"
            d="M5789.2,394.157l11.894,6.853-2.3,3.981-11.891-6.867Z"
            transform="translate(-5773.705 -360.098)"
            fill="#2f303c"
          />
          <path
            id="Path_275"
            data-name="Path 275"
            d="M5801.094,627.39l-11.9,6.852c-.772-1.328-1.521-2.617-2.3-3.963l11.892-6.868Z"
            transform="translate(-5773.7 -536.99)"
            fill="#2f303c"
          />
          <path
            id="Path_276"
            data-name="Path 276"
            d="M6197.727,630.033l-2.3,3.965-11.893-6.866,2.3-3.964Z"
            transform="translate(-6079.748 -536.803)"
            fill="#2f303c"
          />
          <path
            id="Path_277"
            data-name="Path 277"
            d="M6183.527,401.286l11.891-6.864,2.3,3.963-11.892,6.865Z"
            transform="translate(-6079.741 -360.302)"
            fill="#2f303c"
          />
          <path
            id="Path_278"
            data-name="Path 278"
            d="M5955.367,798.323l-1.143-.227c.318-1.51.628-2.979.948-4.5l1.144.229C5956,795.328,5955.692,796.782,5955.367,798.323Z"
            transform="translate(-5902.811 -668.308)"
            fill="#2f303c"
          />
          <path
            id="Path_279"
            data-name="Path 279"
            d="M5753.238,641.872l-.476-1.079,4.2-1.868.475,1.079Z"
            transform="translate(-5747.362 -548.961)"
            fill="#2f303c"
          />
          <path
            id="Path_280"
            data-name="Path 280"
            d="M6259.775,640.013c.164-.373.305-.7.475-1.079l4.2,1.865c-.163.366-.307.686-.479,1.076Z"
            transform="translate(-6138.574 -548.968)"
            fill="#2f303c"
          />
          <path
            id="Path_281"
            data-name="Path 281"
            d="M5956.312,255.208l-1.138.246-.956-4.506,1.141-.226Z"
            transform="translate(-5902.806 -249.423)"
            fill="#2f303c"
          />
          <path
            id="Path_282"
            data-name="Path 282"
            d="M6260.257,416.143l-.474-1.081,4.177-1.861.5,1.071Z"
            transform="translate(-6138.58 -374.792)"
            fill="#2f303c"
          />
          <path
            id="Path_283"
            data-name="Path 283"
            d="M5752.76,414.259l.476-1.078,4.2,1.866-.482,1.077Z"
            transform="translate(-5747.361 -374.777)"
            fill="#2f303c"
          />
          <path
            id="Path_284"
            data-name="Path 284"
            d="M6097.828,262.966l-1.114-.35,1.413-4.377,1.114.364Z"
            transform="translate(-6012.756 -255.223)"
            fill="#2f303c"
          />
          <path
            id="Path_285"
            data-name="Path 285"
            d="M6124.007,273.469,6122.94,273l1.866-4.188,1.068.464Z"
            transform="translate(-6032.992 -263.382)"
            fill="#2f303c"
          />
          <path
            id="Path_286"
            data-name="Path 286"
            d="M6070.563,250.751l1.148.22-.954,4.5-1.14-.227C6069.932,253.748,6070.241,252.28,6070.563,250.751Z"
            transform="translate(-5991.848 -249.445)"
            fill="#2f303c"
          />
          <path
            id="Path_287"
            data-name="Path 287"
            d="M6069.6,793.823l1.144-.228c.319,1.508.63,2.977.953,4.5l-1.145.22C6070.231,796.8,6069.923,795.342,6069.6,793.823Z"
            transform="translate(-5991.835 -668.304)"
            fill="#2f303c"
          />
          <path
            id="Path_288"
            data-name="Path 288"
            d="M6097.823,786.058l1.411,4.346-1.1.4-1.424-4.384Z"
            transform="translate(-6012.753 -662.489)"
            fill="#2f303c"
          />
          <path
            id="Path_289"
            data-name="Path 289"
            d="M6125.895,779.981l-1.065.469-1.866-4.17,1.06-.491Z"
            transform="translate(-6033.011 -654.566)"
            fill="#2f303c"
          />
          <path
            id="Path_290"
            data-name="Path 290"
            d="M5898.272,268.8c.625,1.4,1.233,2.765,1.866,4.183l-1.063.476-1.868-4.191Z"
            transform="translate(-5858.816 -263.373)"
            fill="#2f303c"
          />
          <path
            id="Path_291"
            data-name="Path 291"
            d="M6277.545,586.709c.1-.423.17-.752.257-1.138l4.462.968-.211,1.14Z"
            transform="translate(-6152.286 -507.793)"
            fill="#2f303c"
          />
          <path
            id="Path_292"
            data-name="Path 292"
            d="M5926.628,786.061l1.109.355-1.412,4.377-1.114-.369Z"
            transform="translate(-5880.425 -662.491)"
            fill="#2f303c"
          />
          <path
            id="Path_293"
            data-name="Path 293"
            d="M5927.746,262.542l-1.108.36-1.417-4.346,1.1-.4C5926.8,259.633,5927.266,261.062,5927.746,262.542Z"
            transform="translate(-5880.432 -255.161)"
            fill="#2f303c"
          />
          <path
            id="Path_294"
            data-name="Path 294"
            d="M5872.813,286.747c-.781-1.359-1.527-2.656-2.293-3.988l1.007-.588,2.3,3.988Z"
            transform="translate(-5838.225 -273.688)"
            fill="#2f303c"
          />
          <path
            id="Path_295"
            data-name="Path 295"
            d="M6148.01,286.177l2.292-3.987,1.014.578-2.289,3.988Z"
            transform="translate(-6052.336 -273.704)"
            fill="#2f303c"
          />
          <path
            id="Path_296"
            data-name="Path 296"
            d="M5985.53,250.9l-1.164.1c-.162-1.513-.32-2.995-.486-4.546l1.162-.12C5985.206,247.859,5985.364,249.341,5985.53,250.9Z"
            transform="translate(-5925.694 -246.036)"
            fill="#2f303c"
          />
          <path
            id="Path_297"
            data-name="Path 297"
            d="M6043.08,251.045l-1.161-.1c.157-1.514.311-2.994.474-4.555l1.168.1C6043.4,248.026,6043.244,249.494,6043.08,251.045Z"
            transform="translate(-5970.476 -246.084)"
            fill="#2f303c"
          />
          <path
            id="Path_298"
            data-name="Path 298"
            d="M6041.9,798.366l1.165-.1c.162,1.529.318,3.012.48,4.545l-1.164.109C6042.22,801.381,6042.063,799.9,6041.9,798.366Z"
            transform="translate(-5970.463 -671.91)"
            fill="#2f303c"
          />
          <path
            id="Path_299"
            data-name="Path 299"
            d="M5823.062,733.754l-.866-.788,3.067-3.419.867.791Z"
            transform="translate(-5800.938 -618.885)"
            fill="#2f303c"
          />
          <path
            id="Path_300"
            data-name="Path 300"
            d="M6282.064,470.171c.082.438.143.766.214,1.147l-4.462.968c-.085-.381-.159-.711-.253-1.132Z"
            transform="translate(-6152.299 -418.75)"
            fill="#2f303c"
          />
          <path
            id="Path_301"
            data-name="Path 301"
            d="M6197.515,732.847l-.869.781-3.069-3.4.832-.834Z"
            transform="translate(-6087.496 -618.768)"
            fill="#2f303c"
          />
          <path
            id="Path_302"
            data-name="Path 302"
            d="M5872.809,762.79l1.008.591-2.292,3.984-1.012-.583Z"
            transform="translate(-5838.219 -644.535)"
            fill="#2f303c"
          />
          <path
            id="Path_303"
            data-name="Path 303"
            d="M6148,763.39l1.011-.585,2.294,3.975-1.005.6Z"
            transform="translate(-6052.327 -644.547)"
            fill="#2f303c"
          />
          <path
            id="Path_304"
            data-name="Path 304"
            d="M5984.372,798.236l1.163.1c-.16,1.525-.315,3-.477,4.553l-1.165-.1C5984.054,801.265,5984.209,799.784,5984.372,798.236Z"
            transform="translate(-5925.703 -671.885)"
            fill="#2f303c"
          />
          <path
            id="Path_305"
            data-name="Path 305"
            d="M5822.2,317.852l.867-.788,3.066,3.4-.856.8Z"
            transform="translate(-5800.939 -300.613)"
            fill="#2f303c"
          />
          <path
            id="Path_306"
            data-name="Path 306"
            d="M6194.428,321.283l-.863-.8,3.063-3.4.875.779Z"
            transform="translate(-6087.486 -300.628)"
            fill="#2f303c"
          />
          <path
            id="Path_307"
            data-name="Path 307"
            d="M5898.276,780.467l-1.064-.469,1.864-4.188,1.067.466Z"
            transform="translate(-5858.82 -654.582)"
            fill="#2f303c"
          />
          <path
            id="Path_308"
            data-name="Path 308"
            d="M6217.725,712.627l-.774.884-3.414-3.071.783-.87Z"
            transform="translate(-6102.897 -603.47)"
            fill="#2f303c"
          />
          <path
            id="Path_309"
            data-name="Path 309"
            d="M5801.89,713.5l-.773-.88,3.4-3.068.784.874Z"
            transform="translate(-5784.672 -603.459)"
            fill="#2f303c"
          />
          <path
            id="Path_310"
            data-name="Path 310"
            d="M5801.915,338.161l3.415,3.069-.779.875-3.4-3.049Z"
            transform="translate(-5784.699 -316.891)"
            fill="#2f303c"
          />
          <path
            id="Path_311"
            data-name="Path 311"
            d="M6214.328,342.139l-.783-.875,3.4-3.064.792.866Z"
            transform="translate(-6102.903 -316.921)"
            fill="#2f303c"
          />
          <path
            id="Path_312"
            data-name="Path 312"
            d="M5739.483,471.169l-.249,1.138-4.47-.968.23-1.14Z"
            transform="translate(-5733.476 -418.772)"
            fill="#2f303c"
          />
          <path
            id="Path_313"
            data-name="Path 313"
            d="M5735.02,500.39l-.106,1.169-4.546-.483.083-1.145Z"
            transform="translate(-5730.083 -441.713)"
            fill="#2f303c"
          />
          <path
            id="Path_314"
            data-name="Path 314"
            d="M6286.824,499.912c.032.42.057.753.086,1.144l-4.542.492c-.04-.4-.075-.748-.117-1.168Z"
            transform="translate(-6155.917 -441.698)"
            fill="#2f303c"
          />
          <path
            id="Path_315"
            data-name="Path 315"
            d="M5734.868,557.919l.1,1.169-4.552.468-.113-1.142Z"
            transform="translate(-5730.036 -486.457)"
            fill="#2f303c"
          />
          <path
            id="Path_316"
            data-name="Path 316"
            d="M6282.248,559.1c.042-.429.074-.764.114-1.168l4.546.486c-.032.391-.059.738-.092,1.144Z"
            transform="translate(-6155.915 -486.471)"
            fill="#2f303c"
          />
          <path
            id="Path_317"
            data-name="Path 317"
            d="M5739.4,585.561c.086.415.153.744.234,1.139l-4.362.949c-.081-.069-.127-.088-.135-.118-.289-.991-.287-.987.7-1.2C5737.007,586.084,5738.172,585.827,5739.4,585.561Z"
            transform="translate(-5733.634 -507.785)"
            fill="#2f303c"
          />
          <path
            id="Path_318"
            data-name="Path 318"
            d="M6274.442,615.242l-4.366-1.438.368-1.1,4.343,1.425Z"
            transform="translate(-6146.522 -528.726)"
            fill="#2f303c"
          />
          <path
            id="Path_319"
            data-name="Path 319"
            d="M5746.6,612.682l.371,1.106-4.364,1.439-.36-1.109Z"
            transform="translate(-5739.249 -528.712)"
            fill="#2f303c"
          />
          <path
            id="Path_320"
            data-name="Path 320"
            d="M6235.07,361.427l.684.938-3.708,2.69-.692-.931Z"
            transform="translate(-6116.645 -334.843)"
            fill="#2f303c"
          />
          <path
            id="Path_321"
            data-name="Path 321"
            d="M5742.613,441.183l4.354,1.429-.347,1.113-4.36-1.431Z"
            transform="translate(-5739.259 -396.383)"
            fill="#2f303c"
          />
          <path
            id="Path_322"
            data-name="Path 322"
            d="M6270.442,443.73c-.128-.384-.24-.716-.371-1.108l4.365-1.432.362,1.1Z"
            transform="translate(-6146.519 -396.388)"
            fill="#2f303c"
          />
          <path
            id="Path_323"
            data-name="Path 323"
            d="M5848.116,747.426l.953.674-2.7,3.7-.946-.671Z"
            transform="translate(-5818.862 -632.68)"
            fill="#2f303c"
          />
          <path
            id="Path_324"
            data-name="Path 324"
            d="M6232.07,687.664l3.716,2.694-.683.938-3.707-2.684Z"
            transform="translate(-6116.677 -586.568)"
            fill="#2f303c"
          />
          <path
            id="Path_325"
            data-name="Path 325"
            d="M6171.633,748.109l.951-.676,2.689,3.7-.94.68Z"
            transform="translate(-6070.563 -632.686)"
            fill="#2f303c"
          />
          <path
            id="Path_326"
            data-name="Path 326"
            d="M5786.772,364.129l-.687.937-3.711-2.69.684-.936Z"
            transform="translate(-5770.211 -334.852)"
            fill="#2f303c"
          />
          <path
            id="Path_327"
            data-name="Path 327"
            d="M5786.077,687.672l.683.941-3.71,2.689-.686-.934Z"
            transform="translate(-5770.203 -586.574)"
            fill="#2f303c"
          />
          <path
            id="Path_328"
            data-name="Path 328"
            d="M6174.324,298.423l.943.676-2.687,3.69-.954-.66Z"
            transform="translate(-6070.559 -286.229)"
            fill="#2f303c"
          />
          <path
            id="Path_329"
            data-name="Path 329"
            d="M5849.064,302.12l-.949.667-2.693-3.7.942-.676Z"
            transform="translate(-5818.859 -286.224)"
            fill="#2f303c"
          />
          <path
            id="Path_330"
            data-name="Path 330"
            d="M5770.168,664.043l.586.994-3.971,2.293-.59-.987Z"
            transform="translate(-5757.726 -568.342)"
            fill="#2f303c"
          />
          <path
            id="Path_331"
            data-name="Path 331"
            d="M6246.821,388.864l3.972-2.291.595.986-3.979,2.3Z"
            transform="translate(-6128.579 -354.246)"
            fill="#2f303c"
          />
          <path
            id="Path_332"
            data-name="Path 332"
            d="M5770.8,388.843l-.586.992-3.964-2.282.567-1Z"
            transform="translate(-5757.766 -354.227)"
            fill="#2f303c"
          />
          <path
            id="Path_333"
            data-name="Path 333"
            d="M6246.831,665.049l.586-.994,3.972,2.292-.58.995Z"
            transform="translate(-6128.586 -568.351)"
            fill="#2f303c"
          />
          <path
            id="Path_334"
            data-name="Path 334"
            d="M6014.12,800.035h1.087v4.531h-1.087Z"
            transform="translate(-5949.027 -673.274)"
            fill="#2f303c"
          />
          <path
            id="Path_335"
            data-name="Path 335"
            d="M6014.121,245.035h1.087v4.531h-1.087Z"
            transform="translate(-5949.027 -245.035)"
            fill="#2f303c"
          />
          <path
            id="Path_336"
            data-name="Path 336"
            d="M5733.634,531.223h-4.513v-1.107h4.513Z"
            transform="translate(-5729.121 -465.004)"
            fill="#2f303c"
          />
          <path
            id="Path_337"
            data-name="Path 337"
            d="M6288.61,530.165v1.086h-4.532v-1.086Z"
            transform="translate(-6157.326 -465.042)"
            fill="#2f303c"
          />
        </g>
      </g>
    </svg>
  );
}
