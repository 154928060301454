import React from 'react';

export default ({ className, ...other }) => (
  <svg
    {...other}
    className={`icon w-check ${className || ''}`}
    viewBox="0 0 21.921 15.556"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g xmlns="http://www.w3.org/2000/svg" fill="currentColor">
      <path
        d="m0 0h12v3h-12z"
        transform="matrix(.70710678 .70710678 -.70710678 .70710678 2.121 4.95)"
      />
      <path
        d="m0 0h19v3h-19z"
        transform="matrix(.70710678 -.70710678 .70710678 .70710678 6.364 13.435)"
      />
    </g>
  </svg>
);
