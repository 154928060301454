import React from 'react';

export default ({ className, ...other }) => (
  <svg
    {...other}
    className={`icon w16 ${className || ''}`}
    viewBox="0 0 30.406 30.406"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      xmlns="http://www.w3.org/2000/svg"
      id="Group_50"
      data-name="Group 50"
      transform="translate(-622.297 -219.797)"
    >
      <rect
        id="Rectangle_40"
        data-name="Rectangle 40"
        width="39"
        height="4"
        transform="translate(622.297 247.374) rotate(-45)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_41"
        data-name="Rectangle 41"
        width="39"
        height="4"
        transform="translate(625.125 219.797) rotate(45)"
        fill="currentColor"
      />
    </g>
  </svg>
);
