import React from 'react';
// down
export default ({ className, ...other }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 33.347 27.959"
    {...other}
    className={`icon w-advertising ${className || ''}`}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      id="Path_701"
      data-name="Path 701"
      d="M177.006,280.957a2.391,2.391,0,0,1-.459-1.478c.015-2.053.012-4.142.01-6.162l0-1.6c0-.609-.147-.768-.714-.769-2.785-.009-5.684-.009-8.615,0-.571,0-.727.164-.728.756q0,2.414,0,4.829c0,.665.153.818.817.819h8.219v.291c0,.208,0,.412,0,.613,0,.471,0,.916.007,1.362a1.329,1.329,0,0,1-.438,1.044q-2.756,2.743-5.5,5.5l-2,2c-.076.076-.155.147-.246.229l-.139.126-.206.189-.784-.785c-.428-.429-.848-.85-1.269-1.27-.3-.3-.521-.431-.682-.418s-.348.183-.579.515q-3.793,5.46-7.584,10.922c-.038.055-.078.109-.118.163-.25.338-.363.513-.2.8.148.269.345.273.741.256.074,0,.148-.006.221-.006h0q15.648.008,31.3,0h0c.062,0,.126,0,.19.007a.9.9,0,0,0,.34-.019c.356-.167.462-.3.472-.34a.886.886,0,0,0-.161-.6C184.985,292.327,180.986,286.615,177.006,280.957Zm-1.651-4.793a.687.687,0,0,1-.513.179h-.005c-2.262-.008-4.511-.006-6.62,0h0a.689.689,0,0,1-.509-.176.673.673,0,0,1-.17-.508c.014-1.082.014-2.069,0-3.018a.68.68,0,0,1,.17-.516.733.733,0,0,1,.546-.174c.79.008,1.593.007,2.371.006h1.813c.782,0,1.589,0,2.382-.007a.623.623,0,0,1,.715.7c-.008.964-.007,1.965,0,2.975A.737.737,0,0,1,175.356,276.164Zm-18.138,21.68,7.165-10.322.755.756c.453.453.89.891,1.329,1.326l.075.076a1.143,1.143,0,0,0,.245.213c.219.117.412.118.747-.221q2.112-2.131,4.236-4.252l.408-.409c.8-.8,1.6-1.6,2.418-2.417l1.374-1.372L187.6,297.844Z"
      transform="translate(-155.717 -270.941)"
      fill="currentColor"
    />
  </svg>
);
