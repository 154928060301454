import React from 'react';

export default function Dash({ className, ...other }) {
  return (
    <svg
      {...other}
      className={`icon w-dash ${className || ''}`}
      viewBox="0 0 104 11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M0.917268599,11 L1.73293515,11 L10.7052672,11 L23.755932,11 L44.9632623,11 L63.7235929,9.88888889 L78.4055908,9.88888889 C84.1351969,9.96296296 88.6666667,10 92,10 C95.3333333,10 97.6666667,9.66666667 99,9 C101.291162,7.99806999 102.624495,6.99806999 103,6 C103.375505,5.00193001 103.375505,4.00193001 103,3 C102.802139,1.91992738 102.136278,1.25326071 101.002416,1 C99.8779921,0.748847486 98.2133386,0.748847486 96.0084552,1 L94.010871,1 C89.8807347,1 87.5437777,1 87,1 C86.4562223,1 84.122889,1 80,1 C77.534555,1 74.534555,1 71,1 C67.465445,1 63.15023,1.33333333 58.0543551,2 L42,4 L29.4655978,4.33333333 C22.8238604,4.74977622 17.6579723,5.12014659 13.9679334,5.44444444 C8.43287508,5.93089123 9.88960064,5.44444444 4.1799348,6.55555556 C1.9372477,6.99198645 1.39151595,7.33086836 0.917268599,7.66666667 C0.184127761,8.18577859 0.326703224,8.61827201 0.10160205,8.77777778 C-0.169653187,9.51679229 0.102235663,10.257533 0.917268599,11 Z"
          id="Path"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
