import React from 'react';
import data from '@/layout/data';

import s from './nav.module.scss';

export default {
  ...data,
  social: [
    {
      text: (
        <span className={s.listTitle}>
          <span className={s.titleText}>
            lets get
            <br />
            social!
          </span>
          <span role="img" aria-label="kiss emoji" className={s.emoji}>
            😘
          </span>
        </span>
      )
    },
    ...data.social
  ]
};
