import React from 'react';
import LayoutProvider from 'context/layout-provider';
import Header from './header';
import Footer from './footer';

export default function Layout({ children, ...props }) {
  const noLayout = ['thanks'];
  const isNoLayout =
    typeof window !== 'undefined' && noLayout.includes(props.location.pathname.replace(/\//g, ''));
  if (isNoLayout) return <>{children}</>;
  return (
    <LayoutProvider>
      <Header {...props.location} />
      {children}
      <Footer />
    </LayoutProvider>
  );
}
